<template>
  <GlobalCloseButton @click="navigateBack({name: ROUTES.CELLS_LIST})" />
  <CellEditCard v-bind="$attrs" />
</template>

<script setup lang="ts">

import GlobalCloseButton from '@/components/GlobalCloseButton.vue';
import useNavHelpers from '@/composables/useNavHelpers';
import CellEditCard from '@/views/CellEditCard.vue';
import ROUTES from '@/router/routeNames';

const { navigateBack } = useNavHelpers();
</script>
