<template>
  <ContainerShipButton
    :containers="filteredContainers"
    :loading="progress.fetching"
    @completed="emit('completed')"
  />
</template>

<script setup lang="ts">

import type {
  Container,
  ContainerReportRow,
  QueryContainersByIdsArgs,
} from '@/graphql/types';
import { gql, useClientHandle } from '@urql/vue';
import { reactive } from 'vue';
import useErrorHandling from '@/composables/useErrorHandling';
import { computedAsync } from '@vueuse/core';
import canShipContainer from '@/helpers/canShipContainer';
import ContainerShipButton from '@/components/ContainerShipButton.vue';

const { client: urql } = useClientHandle();
const { clearErrors }  = useErrorHandling();

const props = defineProps<{
  containers: ContainerReportRow[];
}>();

const emit = defineEmits<{
  (e: 'completed'): void;
}>();

const progress = reactive({
  fetching: false,
});

const filteredContainers = computedAsync(
  filterContainers,
  [],
);

async function filterContainers(): Promise<Container[]> {
  progress.fetching = true;
  clearErrors();
  const { data } = await urql.query<{ containersByIds: Container[] }, QueryContainersByIdsArgs>(
    gql`
      query GetContainersForBatchShipping($ids: [ID!]!) {
        containersByIds(ids: $ids) {
          id
          kind
          deletedAt
          isEmpty
          itemsForShipping {
            id
          }
        }
      }
    `,
    { ids: props.containers.map((c: ContainerReportRow) => c.id!) },
  );

  progress.fetching = false;

  return data!.containersByIds.filter((c: Container) => {
    // noinspection OverlyComplexBooleanExpressionJS
    return canShipContainer(c);
  });
}

</script>
