<template>
  <ShipmentsListMobile
    v-if="preferences.terminalMode"
    :extra-filters="extraFilters"
    :fixed-filters="fixedFilters"
    :storage-prefix="mobileStoragePrefix"
  />
  <FullHeightPage v-else>
    <GraphQLQueryTable
      ref="report"
      :graphql-query="query"
      :fields="tableFields"
      :available-filters="availableFilters"
      :fixed-filters="fixedFilters"
      :row-is-deleted="entityIsDeleted"
      :storage-prefix="storagePrefix"
      keep-page-in-url
      :deletion-filter="deletionFilterForSoftDeletable"
      sticky-header
      class="col"
    >
      <template #body-cell-externalId="column">
        <BodyCellLink
          :column="column"
          :to="shipmentCardRoute(column.row)"
        />
      </template>

      <template #body-cell-state="column">
        <QTd :props="column">
          <QChip
            :color="badgeColors.forShipmentState(column.row)"
            text-color="black"
          >
            {{ t('shipmentState.' + column.value) }}
          </QChip>
        </QTd>
      </template>
      <template #batch-actions="{ rows, refresh }">
        <SimpleTableBatchActions :rows-count="rows.length">
          <DeletionWithValidation
            v-if="rows.some(item => item.deletedAt === null)"
            :items="rows"
            :delete="deleteShipment"
            :validate="validateShipment"
            @deleted="refresh()"
          >
            <template #validation-errors="{ invalidItems }">
              <BaseAlert type="error">
                <slot
                  name="validation-message"
                  :ids="invalidItems.map(so => so.externalId ?? so.id)"
                />
              </BaseAlert>
            </template>
            <template #confirmation>
              <QCardSection class="text-body1">
                <slot
                  name="confirmation-message"
                  :not-deleted-items="rows.filter(item => item.deletedAt === null)"
                />
              </QCardSection>
            </template>
          </DeletionWithValidation>
        </SimpleTableBatchActions>
      </template>

      <template #after-search-string="{ options }">
        <slot
          name="import-export"
          v-bind="{ options }"
          @success="report.refresh()"
        />
      </template>
    </GraphQLQueryTable>
  </FullHeightPage>
</template>

<script setup lang="ts">
import BaseAlert from '@/components/BaseAlert.vue';
import type BaseTable from '@/components/BaseTable.vue';
import BodyCellLink from '@/components/BaseTable/BodyCellLink.vue';
import FullHeightPage from '@/components/FullHeightPage.vue';
import GraphQLQueryTable from '@/components/GraphQLQueryTable.vue';
import useLocalizedFormatters, { FORMATS } from '@/composables/useLocalizedFormatters';
import useLocalPreferences from '@/composables/useLocalPreferences';
import getCarriersForSelect from '@/graphql/shorthands/getCarriersForSelect';
import type {
  MutationDeleteCustomerOrderArgs,
  CustomerOrder,
  ShipmentItem,
  ReportFilterInput,
  Shipment,
} from '@/graphql/types';
import { ShipmentStateEnum } from '@/graphql/types';
import * as badgeColors from '@/helpers/badgeColors';
import * as reports from '@/helpers/reports';
import {
  createDatesRangeOperator,
  createList,
  createPrimitive,
  deletionFilterForSoftDeletable,
  entityIsDeleted,
} from '@/helpers/reports';
import shipmentCardRoute from '@/helpers/shipmentCardRoute';
import type { FunctionValidationRule } from '@/types';
import type { ReportFilter } from '@/types/reports';
import DeletionWithValidation from '@/views/DeletionWithValidation.vue';
import SimpleTableBatchActions from '@/views/SimpleTableBatchActions.vue';
import { gql, useClientHandle } from '@urql/vue';
import type { QTableColumn } from 'quasar';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import ShipmentsListMobile from '@/views/Shipping/Shipments/ShipmentsListMobile.vue';
import type { TableColumn } from '@/types/reports';

const { t } = useI18n();

const { dateFormatter } = useLocalizedFormatters();

const { client: urql } = useClientHandle();

const preferences = useLocalPreferences();

const report = ref<InstanceType<typeof BaseTable>>(null);

const props = withDefaults(
  defineProps<{
    extraFields: TableColumn<Shipment>[];
    extraFilters?: ReportFilter[];
    fixedFilters: ReportFilterInput[];
    storagePrefix: string;
    mobileStoragePrefix: string;
  }>(),
  {
    extraFilters: () => [],
  },
);

const tableFields: QTableColumn<CustomerOrder>[] = [
  {
    label: t('Created at'),
    name: 'createdAt',
    field: 'createdAt',
    format: dateFormatter(FORMATS.DATETIME),
    align: 'left',
    sortable: true,
  },
  {
    label: t('Planned Shipment Date'),
    name: 'plannedShipmentDate',
    field: 'plannedShipmentDate',
    format: dateFormatter(FORMATS.DATE),
    align: 'left',
    sortable: true,
  },
  ...props.extraFields,
  {
    label: t('State'),
    name: 'state',
    field: 'state',
    align: 'left',
  },
  {
    label: t('Products'),
    name: 'products',
    field: 'items',
    align: 'left',
    format: (items?: ShipmentItem[]) => String(items?.length ?? ''),
  },
  {
    label: t('Carrier Name'),
    name: 'carrier',
    field: o => o.carrier?.name ?? '',
    align: 'left',
    sortable: true,
  },
  {
    label: t('Priority'),
    name: 'priority',
    field: 'priority',
    sortable: true,
  },
];

const availableFilters: ReportFilter[] = [
  {
    field: 'createdAt',
    label: t('Created at'),
    operators: [createDatesRangeOperator()],
  },
  ...props.extraFilters,
  {
    field: 'state',
    label: t('State'),
    operators: [
      ...(['=', '!=', 'in', 'not in'] as const).map(op =>
        reports.createList(
          op,
          () =>
            Promise.resolve(
              [
                ShipmentStateEnum.CREATED,
                ShipmentStateEnum.IN_PROGRESS,
                ShipmentStateEnum.COMPLETED,
              ].map(value => ({
                value,
                label: t(`shipmentState.${value}`),
              })),
            ),
          t(`reportFilterOperator.${op}`),
        ),
      ),
    ],
  },
  {
    field: 'product',
    label: t('SKU'),
    operators: [
      createPrimitive('=', 'string'),
      createPrimitive('in', 'multiString', {
        label: t('reportFilterOperator.in'),
      }),
    ],
  },
  {
    field: 'carrier',
    label: t('Carrier Name'),
    operators: [createList('in', getCarriersForSelect, t('reportFilterOperator.in'))],
  },
];

async function deleteShipment(shipment: Shipment): Promise<void> {
  await urql.mutation<unknown, MutationDeleteCustomerOrderArgs>(
    gql`
      mutation DeleteCustomerOrder($id: ID!) {
        deleteCustomerOrder(id: $id)
      }
    `,
    { id: shipment.id! },
  );
}

function validateShipment(shipment: Shipment): ReturnType<FunctionValidationRule> {
  return shipment.items.some(i => (i.reservedAmount ?? 0) > 0) ? true : 'notInConfirmedState';
}

const query = gql`
  query GetShipments(
    $page: Int
    $perPage: Int!
    $query: String
    $filter: [ReportFilterInput!]
    $sort: [ReportSortInput!]!
  ) {
    result: shipmentsReport(
      page: $page
      perPage: $perPage
      query: $query
      filter: $filter
      sort: $sort
    ) {
      data {
        id
        createdAt
        plannedShipmentDate
        counterparty {
          id
          name
        }
        state
        ... on SupplierReturn {
          supplyNumber
          supply {
            id
            externalId
          }
        }
        ... on CustomerOrder {
          type
        }
        externalId
        carrier {
          id
          name
        }
        deletedAt
        items {
          id
          reservedAmount
        }
        priority
      }
      total
    }
  }
`;
</script>
