<template>
  <GlobalCloseButton @click="navigateBack({ name: ROUTES.PRODUCTS_LIST })" />
  <ProductEditCard
    v-bind="$attrs"
    goto-product-on-scan
    @goto-product="router.replace({ name: ROUTES.PRODUCTS_EDIT, params: { id: $event } })"
  />
</template>

<script setup lang="ts">

import GlobalCloseButton from '@/components/GlobalCloseButton.vue';
import useNavHelpers from '@/composables/useNavHelpers';
import ProductEditCard from '@/views/Products/ProductEditCard.vue';
import { useRouter } from 'vue-router';
import ROUTES from '@/router/routeNames';

const { navigateBack } = useNavHelpers();

const router = useRouter();

</script>
