<template>
  <FullHeightPage>
    <DashboardTabs storage-key="selectionDashboardTab">
      <template #pending>
        <SelectionsListForDashboard
          storage-prefix="selectionsList.pending"
          :states="[
            SelectionStateEnum.CREATED,
            SelectionStateEnum.SELECTING,
          ]"
        />
      </template>
      <template #completed>
        <SelectionsListForDashboard
          storage-prefix="selectionsList.completed"
          :states="[
            SelectionStateEnum.COMPLETED,
          ]"
        />
      </template>
    </DashboardTabs>
    <ButtonsRow v-slot="{ buttonProps }">
      <QBtn
        v-bind="buttonProps"
        icon="mdi-plus-circle"
        :to="{ name: ROUTES.SELECTIONS_NEW }"
      >
        {{ t('Create') }}
      </QBtn>
    </ButtonsRow>
  </FullHeightPage>
</template>

<script setup lang="ts">

import FullHeightPage from '@/components/FullHeightPage.vue';
import ButtonsRow from '@/components/Mobile/ButtonsRow.vue';
import useBreadcrumbs from '@/composables/useBreadcrumbs';
import { useI18n } from 'vue-i18n';
import SelectionsListForDashboard from '@/views/Mobile/Selection/SelectionsListForDashboard.vue';
import DashboardTabs from '@/components/DashboardTabs.vue';
import { SelectionStateEnum } from '@/graphql/types';
import ROUTES from '@/router/routeNames';

const { t } = useI18n();

useBreadcrumbs(t('Selection'));

</script>

<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/selection.yaml"></i18n>
