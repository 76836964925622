<template>
  <FullHeightPage v-if="preferences.terminalMode">
    <GraphQLReportList
      ref="listEl"
      :graphql-query="mobileQuery"
      :available-filters="availableFilters"
      with-search
      class="col"
      blurred-search
      storage-prefix="containersList.mobileReport"
      @single-item-found="handleSingleContainerFound"
    >
      <template #item="{ item }">
        <QItem :to="{ name: ROUTES.CONTAINERS_EDIT, params: { id: item.id } }">
          <QItemSection>
            <QItemLabel>
              {{ item.name }}
            </QItemLabel>
            <QItemLabel>
              {{ item.barcode }}
            </QItemLabel>
          </QItemSection>

          <QItemSection side>
            <QItemLabel>
              {{ item.storageCell.name }}
            </QItemLabel>
            <QItemLabel>
              <template v-if="item.isEmpty">
                {{ t('Empty') }}
              </template>
              <template v-else>
                {{ t('{n} products', item.stocks.length) }}
              </template>
            </QItemLabel>
          </QItemSection>
        </QItem>
      </template>
    </GraphQLReportList>
    <ButtonsRow v-slot="{ buttonProps }">
      <CreateContainer @close="listEl.reload()">
        <template #activator="{ onClick }">
          <QBtn
            v-bind="buttonProps"
            icon="mdi-plus-circle-outline"
            @click="onClick"
          >
            {{ t('Create') }}
          </QBtn>
        </template>
      </CreateContainer>
    </ButtonsRow>
  </FullHeightPage>

  <FullHeightPage v-else>
    <GraphQLQueryTable
      ref="report"
      :graphql-query="query"
      :fields="tableFields"
      :available-filters="availableFilters"
      :row-is-deleted="entityIsDeleted"
      storage-prefix="containersList.report"
      keep-page-in-url
      :deletion-filter="deletionFilterForSoftDeletable"
      sticky-header
      class="col"
      @single-item-found="handleSingleContainerFound"
    >
      <template #after-search-string="{ options }">
        <QBtn
          color="primary"
          :to="{ name: ROUTES.CONTAINERS_NEW }"
          icon="mdi-plus"
          :label="t('Add Container')"
        />
        <ContainersExcelImportExport
          :options="options"
          @success="report.refresh()"
        />
      </template>

      <template #batch-actions="{ rows, refresh, clear }">
        <SimpleTableBatchActions :rows-count="rows.length">
          <DeletionWithValidation
            v-if="rows.some(item => item.deletedAt === null)"
            :items="rows"
            :delete="deleteContainer"
            :validate="validateContainer"
            @deleted="refresh()"
          >
            <template #validation-errors="{ invalidItems }">
              <BaseAlert type="error">
                {{
                  t(
                    'Containers {names} with stocks cannot be deleted',
                    {names: invalidItems.map(c => c.name).join(', ')},
                    invalidItems.length,
                  )
                }}
              </BaseAlert>
            </template>
            <template #confirmation>
              <QCardSection class="text-body1">
                {{ getConfirmMessageForDeletion(rows) }}
              </QCardSection>
            </template>
          </DeletionWithValidation>

          <RestoreItemsButton
            :items="rows"
            :restore="restoreContainer"
            @restored="refresh()"
          >
            <template #confirmation>
              <div class="text-h6">
                {{ getConfirmMessageForRestoring(rows) }}
              </div>
            </template>
          </RestoreItemsButton>

          <ContainersBatchShipButton
            :containers="rows"
            class="bg-white text-black q-ml-sm"
            @completed="refresh()"
          />

          <PrintDialog
            v-slot="{ open }"
            v-model:barcode-type="barcodeType"
            template-name="container-barcode"
            :title="rows[0].name"
            :instances="printInstancesByContainerRows(rows)"
            use-count
            @count-changed="printCount=$event"
            @print="clear();"
          >
            <QBtn
              class="bg-white text-black q-ml-sm"
              icon="mdi-printer"
              @click="open()"
            >
              {{ t('Print') }}
            </QBtn>
          </PrintDialog>
        </SimpleTableBatchActions>
      </template>

      <template #body-cell-name="column">
        <BodyCellLink
          :column="column"
          :to="{ name: ROUTES.CONTAINERS_EDIT, params: { id: String(column.row.id) } }"
        />
      </template>

      <template #body-cell-cellName="column">
        <BodyCellLink
          dense
          variant="link"
          :column="column"
          :to="{ name: ROUTES.CELLS_EDIT, params: { id: String(column.row.cellId) } }"
        />
      </template>
    </GraphQLQueryTable>
  </FullHeightPage>
</template>

<script setup lang="ts">

import BaseAlert from '@/components/BaseAlert.vue';
import type BaseTable from '@/components/BaseTable.vue';
import BodyCellLink from '@/components/BaseTable/BodyCellLink.vue';
import FullHeightPage from '@/components/FullHeightPage.vue';
import GraphQLQueryTable from '@/components/GraphQLQueryTable.vue';
import ButtonsRow from '@/components/Mobile/ButtonsRow.vue';
import GraphQLReportList from '@/components/Mobile/GraphQLReportList.vue';
import PrintDialog from '@/components/PrintDialog.vue';
import useBreadcrumbs from '@/composables/useBreadcrumbs';
import useLocalizedFormatters, { FORMATS } from '@/composables/useLocalizedFormatters';
import useLocalPreferences from '@/composables/useLocalPreferences';
import type { PrintTemplateInstance } from '@/composables/usePrintService';
import type {
  Container,
  ContainerReportRow,
  MutationDeleteContainerArgs,
  MutationRestoreContainerArgs,
} from '@/graphql/types';
import { ContainerKindEnum, PrintBarcodeTypeEnum } from '@/graphql/types';
import * as reports from '@/helpers/reports';
import {
  createDatesRangeOperator,
  deletionFilterForSoftDeletable,
  entityIsDeleted,
} from '@/helpers/reports';
import ROUTES from '@/router/routeNames';
import type { FunctionValidationRule } from '@/types';
import type { ReportFilter, TableColumn } from '@/types/reports';
import ContainersBatchShipButton from '@/views/Containers/ContainersBatchShipButton.vue';
import ContainersExcelImportExport from '@/views/Containers/ContainersExcelImportExport.vue';
import DeletionWithValidation from '@/views/DeletionWithValidation.vue';
import CreateContainer from '@/views/Mobile/CreateContainer.vue';
import RestoreItemsButton from '@/views/RestoreItemsButton.vue';
import SimpleTableBatchActions from '@/views/SimpleTableBatchActions.vue';
import useDocumentsPrintingState from '@/views/useDocumentsPrintingState';
import { gql, useClientHandle } from '@urql/vue';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

const { t } = useI18n();

const { dateFormatter, formatWeight } = useLocalizedFormatters();

useBreadcrumbs(t('Containers'));

const { client: urql } = useClientHandle();

const report = ref<InstanceType<typeof BaseTable>>(null);

const state = useDocumentsPrintingState();

const barcodeType = ref(state.value.lastContainerBarcodeType ?? PrintBarcodeTypeEnum.BARCODE);
const printCount = ref(1);

function printInstancesByContainerRows(rows: ContainerReportRow[]): PrintTemplateInstance[] {
  return rows.map(c => ({ containerId: c.id!, barcodeType: barcodeType.value }))
    .map(p => {
      return { count: printCount.value, params: p, };
    });
}

const tableFields: TableColumn<ContainerReportRow>[] = [
  {
    label:         t('Name'),
    name:          'name',
    field:         'name',
    requestFields: ['id', 'name'],
    mandatory:     true,
    align:         'left',
    sortable:      true,
  },
  {
    label:    t('Barcode'),
    name:     'barcode',
    field:    'barcode',
    align:    'left',
    sortable: true,
  },
  {
    label:    t('Capacity, kg of Volume Weight'),
    name:     'capacity',
    field:    'capacity',
    format:   formatWeight,
    sortable: true,
  },
  {
    label:    t('Created at'),
    name:     'createdAt',
    field:    'createdAt',
    format:    dateFormatter(FORMATS.DATETIME),
    align:    'left',
    sortable: true,
  },
  {
    label:         t('Storage Cell'),
    name:          'cellName',
    field:         'cellName',
    requestFields: ['cellId', 'cellName'],
    align:         'left',
  },
  {
    label:  t('Is Empty'),
    name:   'isEmpty',
    field:  'isEmpty',
    format: isEmpty => isEmpty ? '' : t('No'),
    align:  'left',
  },
  {
    label: t('Products Amount'),
    name: 'productsAmount',
    field: 'productsAmount',
  },
  {
    label: t('Products pcs'),
    name: 'productsPieces',
    field: 'productsPieces',
  },
  {
    label: t('Weight'),
    name:  'productsWeight',
    field: 'productsWeight',
    format: weight => formatWeight(weight),
  },
  {
    label: t('Kind'),
    name:  'kind',
    field: 'kind',
    format: (kind) => t(`containerKind.${kind}`),
    align: 'left',
  },
  {
    label: t('Orders'),
    name:  'shipments',
    field: 'shipments',
    format: shipments => shipments?.join(', '),
    align: 'left',
  },
];

const availableFilters: ReportFilter[] = [
  {
    field:                 'createdAt',
    label:                 t('Created at'),
    hideLabelWhenSelected: true,
    operators:             [
      createDatesRangeOperator(),
    ],
  },
  {
    field:     'isEmpty',
    label:                 t('Is Empty'),
    hideLabelWhenSelected: true,
    operators:             [
      reports.createBoolean('is null', ' - ', {
        trueLabel: t('Is Empty'),
        falseLabel: t('Is Not Empty'),
      }),
    ],
  },
  {
    field:                 'kind',
    label:                 t('Kind'),
    hideLabelWhenSelected: true,
    operators:             [
      reports.createList('=', reports.enumValues(
        ContainerKindEnum, kind =>  t('containerKind.' + kind),
      )),
    ],
  },
  {
    field:                 'externalId',
    label:                 t('Order'),
    hideLabelWhenSelected: true,
    operators:             [
      reports.createPrimitive('=', 'string'),
    ],
  },
];

watch(barcodeType, (): void => {
  state.value.lastContainerBarcodeType = barcodeType.value;
});

async function deleteContainer(container: Container): Promise<void> {
  await urql.mutation<unknown, MutationDeleteContainerArgs>(
    gql`mutation DeleteContainer($id: ID!) { deleteContainer(id: $id) }`,
    { id: container.id },
  );
}

function validateContainer(container: ContainerReportRow): ReturnType<FunctionValidationRule> {
  return container.isEmpty ? true : 'isNotEmpty';
}

function getContainerNameOrCode(container: ContainerReportRow): string {
  return container.name || String(container.id);
}

const query = gql`
  query GetContainersReport(
    $page: Int,
    $perPage: Int!,
    $query: String,
    $filter: [ReportFilterInput!],
    $sort: [ReportSortInput!]!,
  ) {
    result: containersReport(
      page: $page,
      perPage: $perPage,
      query: $query,
      filter: $filter,
      sort: $sort,
    ) {
      data {
        id
        name
        barcode
        capacity
        createdAt
        deletedAt
        cellId
        cellName
        isEmpty
        productsAmount
        productsPieces
        productsWeight
        kind
        shipments
      }
      total
    }
  }
`;

const mobileQuery = gql`
  query GetContainersListMobile(
    $first: Int!,
    $after: Cursor,
    $query: String,
    $filter: [ReportFilterInput!],
  ) {
    result: containersList(
      first: $first,
      after: $after,
      query: $query,
      filter: $filter,
    ) {
      edges {
        cursor
        node {
          id
          name
          barcode
          storageCell { id name }
          stocks { storageUnit { id } }
          isEmpty
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`;

function getConfirmMessageForDeletion(items: ContainerReportRow[]): string {
  const notDeletedItems = items.filter(item => item.deletedAt === null);
  return t(
    'Delete {n} containers?',
    { name: getContainerNameOrCode(notDeletedItems[0]) },
    notDeletedItems.length
  );
}

function getConfirmMessageForRestoring(items: ContainerReportRow[]): string {
  const deletedItems = items.filter(item => item.deletedAt !== null);
  return t(
    'Restore {n} containers?',
    { name: getContainerNameOrCode(deletedItems[0]) },
    deletedItems.length
  );
}

async function restoreContainer(container: Container): Promise<void> {
  await urql.mutation<unknown, MutationRestoreContainerArgs>(
    gql`mutation RestoreContainer($id: ID!) { restoreContainer(id: $id) { id } }`,
    { id: container.id },
  );
}

const preferences = useLocalPreferences();

const router = useRouter();

function handleSingleContainerFound(container: Container, searchString: string) {
  if (searchString === container.barcode) {
    router.push({ name: ROUTES.CONTAINERS_EDIT, params: { id: container.id } });
  }
}

const listEl = ref();

</script>

<i18n lang="yaml" src="../../plugins/i18n/sharedMessages/counts.yaml"></i18n>

<i18n lang="yaml">
ru:
  Add Container: Добавить контейнер
  Active: Активность
  Is Empty: Пустой
  Is Not Empty: Непустой
  Products Amount: Товары кол-во
  Products pcs: Товары шт.
  Kind: Тип
  Containers {names} with stocks cannot be deleted: >
    Нельзя удалить контейнеры {names}, для которых есть остатки
    | Нельзя удалить контейнер {names}, для которого есть остатки
    | Нельзя удалить контейнеры {names}, для которых есть остатки
    | Нельзя удалить контейнеры {names}, для которых есть остатки
  Delete {n} containers?: >
    Удалить {n} контейнеров?
    | Удалить контейнер "{name}"?
    | Удалить {n} контейнера?
    | Удалить {n} контейнеров?
  Restore {n} containers?: >
    Восстановить {n} контейнеров?
    | Восстановить контейнер {name}?
    | Восстановить {n} контейнера?
    | Восстановить {n} контейнеров?
en:
  Add Container: Add Container
  Active: Active
  Is Empty: Is Empty
  Is Not Empty: Is Not Empty
  Kind: Kind
  Products Amount: Products Amount
  Products pcs: Products pcs
  Containers {names} with stocks cannot be deleted: >
    Container {names} with stocks cannot be deleted
    | Containers {names} with stocks cannot be deleted
  Delete {n} containers?: >
    Delete container "{name}"?
    | Delete {n} containers?
  Restore {n} containers?: >
    Restore container "{name}"?
    | Restore {n} containers?
</i18n>
