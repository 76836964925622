<template>
  <CardTitle>
    {{ supply.externalId }}
  </CardTitle>

  <div
    v-if="!noHeader"
    class="q-pb-md"
  >
    <HorizontalPropsList v-slot="{ item }">
      <component
        :is="item"
        :caption="t('Code')"
      >
        {{ supply.id }}
      </component>
      <component
        :is="item"
        :caption="t('Created at')"
      >
        {{ formatDate(supply.createdAt) }}
      </component>
      <component
        :is="item"
        v-if="supply.counterparty"
        :caption="isCustomerReturn ? t('Customer') : t('Supplier')"
      >
        {{ supply.counterparty?.name ?? '' }}
      </component>
      <component
        :is="item"
        :caption="t('State')"
      >
        <QChip
          dense
          class="q-ma-none"
          :color="forSupplyState(supply)"
          text-color="black"
        >
          {{ t('supplyState.' + supply.state) }}
        </QChip>
      </component>
      <component
        :is="item"
        v-if="supply.plannedArrivalDate"
        :caption="t('Arrival Date')"
      >
        {{ formatDate(supply.plannedArrivalDate) }}
      </component>
      <component
        :is="item"
        :caption="t('Products')"
      >
        {{ R.uniqBy(i => i.productPack.product.sku, supply.items).length }}
      </component>
    </HorizontalPropsList>
  </div>

  <GraphQLReportList
    with-search
    :graphql-query="query"
    :fixed-filters="fixedFilters"
    :row-is-deleted="item => item.productPack.deletedAt !== null"
  >
    <template #item="{ item }">
      <ProductListItem
        :product="item.productPack.product"
        :to="{ name: ROUTES.PRODUCTS_EDIT, params: { id: item.productPack.product.id } }"
      >
        <template #top-right>
          <AmountCompletionChip
            :product-pack="item.productPack"
            :expected-amount="item.amount"
            :actual-amount="item.arrivedAmount"
          />
        </template>
      </ProductListItem>
    </template>
  </GraphQLReportList>
</template>

<script setup lang="ts">

import CardTitle from '@/components/CardTitle.vue';
import AmountCompletionChip from '@/components/Mobile/AmountCompletionChip.vue';
import GraphQLReportList from '@/components/Mobile/GraphQLReportList.vue';
import ProductListItem from '@/components/ProductListItem.vue';
import useLocalizedFormatters from '@/composables/useLocalizedFormatters';
import ProductPackForEditDialog from '@/graphql/fragments/ProductPackForEditDialog';
import type {
  Arrival,
  CustomerReturn,
  ReportFilterInput,
  SupplierDelivery,
} from '@/graphql/types';
import { forSupplyState } from '@/helpers/badgeColors';
import ROUTES from '@/router/routeNames';
import useProductArrivalStore from '@/stores/productArrival';
import HorizontalPropsList from '@/views/Mobile/HorizontalPropsList.vue';
import { gql } from '@urql/vue';
import * as R from 'ramda';
import { computed, onBeforeMount, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const { formatDate } = useLocalizedFormatters();

const store = useProductArrivalStore();

const props = defineProps<{
  supply: CustomerReturn | SupplierDelivery;
  noHeader?: boolean;
}>();

const isCustomerReturn = computed(() => props.supply.__typename === 'CustomerReturn');

const fetchingArrival = ref(false);

const arrivalInProgress = ref<Arrival | null>(null);

onBeforeMount(async function created(): Promise<void> {
  fetchingArrival.value = true;
  arrivalInProgress.value = await store.getArrivalBySupply(props.supply);
  fetchingArrival.value = false;
});

const fixedFilters = computed<ReportFilterInput[]>(() => ([ {
  field:    'supplyId',
  operator: '=',
  value:    JSON.stringify(props.supply.id),
} ]));

const query = gql`
  query GetSupplyItems(
    $first: Int!,
    $after: Cursor,
    $query: String,
    $filter: [ReportFilterInput!],
  ) {
    result: supplyItemsList(
      first: $first,
      after: $after,
      query: $query,
      filter: $filter,
    ) {
      edges {
        cursor
        node {
          id
          arrivedAmount
          amount
          productPack {
            ...ProductPackForEditDialog
            measurementUnit { id name class shortName }
            smallerProductPack { id quantity measurementUnit { id shortName }}
            quantityInMinMeasurementUnits
            minMeasurementUnit { id shortName }
            deletedAt
          }
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
  ${ ProductPackForEditDialog }
`;

</script>

<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/counts.yaml"></i18n>

<i18n lang="yaml">

ru:
  Code: Код
  Start Arrival: Начать приёмку
  Continue Arrival: Продолжить приёмку

en:
  Code: Code
  Start Arrival: Start Arrival
  Continue Arrival: Continue Arrival

</i18n>
