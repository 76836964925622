<template>
  <SelectionDetailsCard
    :selection="selection"
    :loading="progress.fetching"
    @back="navigateBack({ name: ROUTES.SELECTIONS_LIST })"
  />
</template>

<script setup lang="ts">

import useNavHelpers from '@/composables/useNavHelpers';
import MovementForSelection from '@/graphql/fragments/MovementForSelection';
import type { QuerySelectionArgs, Scalars, Selection } from '@/graphql/types';
import SelectionDetailsCard from '@/views/Shipping/SelectionDetailsCard.vue';
import { gql, useClientHandle } from '@urql/vue';
import { onBeforeMount, reactive, ref } from 'vue';
import ROUTES from '@/router/routeNames';

const { navigateBack } = useNavHelpers();

const { client: urql } = useClientHandle();

const props = defineProps<{
  id: Scalars['ID'];
}>();

const progress = reactive({
  fetching: false,
});

const selection = ref<Selection | null>(null);

onBeforeMount(async function created(): Promise<void> {
  progress.fetching = true;
  await loadSelection();
  progress.fetching = false;
});

async function loadSelection(): Promise<void> {
  const { data } = await urql.query<{ selection: Selection | null }, QuerySelectionArgs>(
    gql`
      query GetSelectionForQueuedSelectionsList($id: ID!) {
      ${MovementForSelection}
        selection(id: $id) {
          id
          createdAt
          state
          kind
          movements {...MovementForSelection }
          shipments {
            id
            externalId
            carrier { id name }
            items {
              id
              amount
              productPack {
                id
                quantityInMinMeasurementUnits
                minMeasurementUnit { id shortName }
              }
            }
          }
          selectionOrders {
            id
            shipment {
              id
            }
            container {
              id
            }
          }
          items {
            id
            amount
            shipment {
              id
              externalId
            }
            productPack {
              id
              product { id sku name }
              quantityInMinMeasurementUnits
            }
          }
        }
      }
    `,
    { id: props.id },
  );

  selection.value = data!.selection;
}
</script>
