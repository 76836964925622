<template>
  <QPage padding>
    <QCard>
      <PrimaryErrorBanner />
      <QCardSection
        v-if="fetching"
        class="text-center"
      >
        <BigLoading />
      </QCardSection>
      <template v-else-if="realization">
        <CardTitle>
          {{ t('operationType.Realization') }}
          {{
            t('{id} dated {date}', {
              id: realization.id,
              date: formatDate(realization.createdAt, FORMATS.DATETIME),
            })
          }}
          <OperationState :operation="realization" />
        </CardTitle>

        <QCardSection class="row q-gutter-sm">
          <div class="col-2">
            <NonEditableField :label="t('User')">
              {{ userFullName(realization.user) }}
            </NonEditableField>
          </div>
          <div class="col-2">
            <QField
              :label="t('Order')"
              stack-label
            >
              <LinksList
                :max-to-show="3"
                :items="shipments"
              >
                <template #item="{ item }">
                  <RouterLink :to="shipmentCardRoute(item)">
                    {{ item.externalId }}
                  </RouterLink>
                </template>
              </LinksList>
            </QField>
          </div>
        </QCardSection>

        <BaseTable
          :rows="realization.items"
          :fields="itemsTableFields"
        >
          <template #body-cell-productSku="column">
            <BodyCellLink
              :column="column"
              :to="{ name: ROUTES.PRODUCTS_EDIT, params: { id: column.row.productPack.product.id } }"
              variant="link"
            />
          </template>
          <template #body-cell-container="column">
            <BodyCellLink
              :column="column"
              :to="{ name: ROUTES.CONTAINERS_EDIT, params: { id: column.value.id } }"
              variant="link"
            >
              {{ column.value.name }}
            </BodyCellLink>
          </template>
        </BaseTable>

        <QCardActions>
          <QBtn
            icon="mdi-arrow-left"
            @click="navigateBack({ name: ROUTES.REALIZATIONS_LIST })"
          >
            {{ t('Back' ) }}
          </QBtn>
        </QCardActions>
      </template>
      <template v-else>
        <RealizationNotFoundBanner />
      </template>
    </QCard>
  </QPage>
</template>

<script setup lang="ts">

import BodyCellLink from '@/components/BaseTable/BodyCellLink.vue';
import LinksList from '@/components/LinksList.vue';
import useNavHelpers from '@/composables/useNavHelpers';
import type { QueryRealizationArgs, Realization, RealizationItem } from '@/graphql/types';
import shipmentCardRoute from '@/helpers/shipmentCardRoute';
import { gql, useQuery } from '@urql/vue';
import { uniqBy } from 'ramda';
import type { Component } from 'vue';
import { computed, h, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import CardTitle from '@/components/CardTitle.vue';
import BigLoading from '@/components/BigLoading.vue';
import useLocalizedFormatters, { FORMATS } from '@/composables/useLocalizedFormatters';
import OperationState from '@/components/OperationState.vue';
import useErrorHandling from '@/composables/useErrorHandling';
import userFullName from '@/helpers/userFullName';
import NonEditableField from '@/components/NonEditableField.vue';
import BaseTable from '@/components/BaseTable.vue';
import type { QTableColumn } from 'quasar';
import ROUTES from '@/router/routeNames';
import NotFoundBanner from '@/components/NotFoundBanner.vue';

const { navigateBack } = useNavHelpers();

const { t } = useI18n();

const { formatDate } = useLocalizedFormatters();

// noinspection LocalVariableNamingConventionJS
const { fillErrorsFromGraphQLError, PrimaryErrorBanner } = useErrorHandling();

const RealizationNotFoundBanner: Component = () => h(NotFoundBanner, {
  notFoundMessage: t('Realization not found'),
  backRoute:       { name: ROUTES.REALIZATIONS_LIST },
  goBackMessage:   t('Go to Realizations List'),
});


const props = defineProps<{
  id: string;
}>();

const { data, error, fetching } = useQuery<{ realization: Realization }, QueryRealizationArgs>({
  query:     gql`
    query GetRealizationForCard($id: ID!) {
      realization(id: $id) {
        id
        createdAt
        state
        user { id firstname lastname }
        items {
          id
          shipment { id externalId }
          container { id name }
          productPack {
            id
            product { id sku name }
          }
          amount
        }
      }
    }
  `,
  variables: computed(() => ({ id: props.id! })),
});
const realization = computed(() => data.value?.realization);

watch(error, fillErrorsFromGraphQLError);

const shipments = computed(() => uniqBy(s => s.id, realization.value?.items.map(i => i.shipment) ?? []));

const itemsTableFields: QTableColumn<RealizationItem>[] = [
  {
    label:  t('Container'),
    name:   'container',
    field:  'container',
  },
  {
    label:  t('SKU'),
    name:   'productSku',
    field:  item => item.productPack.product.sku,
    align:  'left',
  },
  {
    label:  t('Product'),
    name:   'productName',
    field:  item => item.productPack.product.name,
    align:  'left',
  },
  {
    label:  t('Amount'),
    name:   'amount',
    field:  'amount',
  },
];

</script>
<i18n lang="yaml">
ru:
  Realization not found: Отгрузка не найдена
  Go to Realizations List: Перейти к списку Отгрузок

en:
  Realization not found: Realization not found
  Go to Realizations List: Go to Realizations List
</i18n>
