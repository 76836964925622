<template>
  <GraphQLReportList
    ref="list"
    v-bind="$attrs"
    :graphql-query="query"
    :fixed-filters="fixedFilters"
    :no-data-label="t('No Tasks')"
    :storage-prefix="storagePrefix"
  >
    <template #item="{ item }">
      <TransferTaskListItem
        :transfer-task="item"
        clickable
        @click="handleItemClick(item)"
      />
    </template>
  </GraphQLReportList>
  <TransferTaskDialogForDashboardList
    :transfer-task="transferTask"
    @close="transferTask = null"
    @changed="itemsChanged($event)"
  />
</template>

<script setup lang="ts">

import GraphQLReportList from '@/components/Mobile/GraphQLReportList.vue';
import type {  TransferTask,  TransferStateEnum } from '@/graphql/types';
import { type ReportFilterInput } from '@/graphql/types';
import { gql } from '@urql/vue';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import TransferTaskListItem from '@/views/Mobile/TransferTask/TransferTaskListItem.vue';
import TransferTaskDialogForDashboardList from '@/views/Mobile/TransferTask/TransferTaskDialogForDashboardList.vue';
import TransferTaskForDashboardList from '@/graphql/fragments/TransferTaskForDashboardList';

const { t } = useI18n();

const props = defineProps<{
  states: TransferStateEnum[];
  storagePrefix: string;
}>();

const list = ref();

function itemsChanged(task: TransferTask) {
  list.value.reload();
  transferTask.value = task;
}

const query = gql`
  query TransferTasksForDashboard(
    $first: Int!,
    $after: Cursor,
    $query: String,
    $filter: [ReportFilterInput!],
  ) {
    result: transferTasksList(
      first: $first,
      after: $after,
      query: $query,
      filter: $filter,
    ) {
      edges {
        cursor
        node {
          ...TransferTaskForDashboardList
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
    ${TransferTaskForDashboardList}
  }
`;

const fixedFilters = computed<ReportFilterInput[]>(() => [{
  field:    'state',
  operator: 'in',
  value:    JSON.stringify(props.states),
}]);

const transferTask = ref<TransferTask | null>(null);

async function handleItemClick(item: TransferTask): void {
  transferTask.value = item;
}
</script>

<i18n lang="yaml">
ru:
  Continue Transfer: Продолжить перемещение
  Create Transfer: Создать перемещение
  No Tasks: Задания отсутствуют

en:
  Continue Transfer: Continue Transfer
  Create Transfer: Create Transfer
  No Tasks: No Tasks

</i18n>
