<template>
  <GlobalCloseButton @click="navigateBack({name: ROUTES.CONTAINERS_LIST})" />
  <ContainerDetailsMobile v-bind="$attrs" />
</template>

<script setup lang="ts">

import GlobalCloseButton from '@/components/GlobalCloseButton.vue';
import useBreadcrumbs from '@/composables/useBreadcrumbs';
import useNavHelpers from '@/composables/useNavHelpers';
import ContainerDetailsMobile from '@/views/Containers/ContainerDetailsMobile.vue';
import { useI18n } from 'vue-i18n';
import ROUTES from '@/router/routeNames';

const { navigateBack } = useNavHelpers();

const { t } = useI18n();

useBreadcrumbs([t('Containers')]);

</script>
