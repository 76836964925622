<template>
  <QPage padding>
    <BigLoading v-if="fetching" />
    <BaseAlert
      v-else-if="notFound"
      type="info"
      icon="mdi-run-fast"
    >
      <p>
        {{ t('Product not found') }}
      </p>
      <QBtn
        exact
        color="primary"
        icon="mdi-account-group"
        :to="{ name: ROUTES.PRODUCTS_LIST }"
      >
        {{ t('Go to Products list') }}
      </QBtn>
    </BaseAlert>
    <QCard v-else>
      <PrimaryErrorBanner />
      <CardTitle>
        {{ product.name }}
        <QBadge
          v-if="product.deletedAt"
          rounded
          color="error"
          :label="t('Deleted')"
          :title="t('Deleted {at}', { at: formatDate(product.deletedAt) })"
        />
      </CardTitle>
      <QCardSection class="row q-col-gutter-md">
        <div class="col-12 col-xl-4">
          <QInput
            v-model="product.name"
            :label="t('Name')"
            :rules="[notEmptyRule]"
            v-bind="qErrorsFor('name')"
          />
        </div>
        <div
          v-if="product.baseName"
          class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-1"
        >
          <QInput
            :model-value="product.baseName"
            :label="t('Nomenclature')"
            readonly
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-3">
          <QSelect
            v-model="category"
            :options="categories"
            use-input
            fill-input
            hide-selected
            :label="t('Category')"
            :title="category.label"
            @filter="categoriesFilter"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-1">
          <QInput
            v-model="product.sku"
            :label="t('SKU')"
            :rules="[notEmptyRule]"
            v-bind="qErrorsFor('sku')"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2">
          <QSelect
            v-model="supplier"
            :options="suppliers"
            use-input
            fill-input
            hide-selected
            :label="t('Supplier')"
            @filter="suppliersFilter"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2">
          <QSelect
            v-model="product.accountingModel"
            :options="accModels"
            :loading="accountingModelsLoading"
            :disable="accountingModelsLoading"
            :label="t('Accounting model')"
            :placeholder="t('Not specified')"
            option-label="name"
            value="name"
            clearable
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-1">
          <SingleUnitDuration
            v-if="product.accountingModel.byShelfLife"
            v-model="product.shelfLife"
            min="1"
            :rules="[positiveIntegerOrNullRule]"
            :label="t('Shelf life (days)')"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-2">
          <QCheckbox
            v-model="product.dontPrintName"
            :label="t('Dont print Name')"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-2">
          <QInput
            v-model="product.extraDataForPrinting"
            :label="t('Extra Data for Printing')"
            clearable
            v-bind="qErrorsFor('name')"
          />
        </div>
      </QCardSection>

      <QSeparator />

      <QCardSection>
        <p>
          {{ t('Photos') }}
        </p>
        <PhotosForm
          v-model:photos="productPhotos"
          :errors="errorsFor<Record<number, { data: string[] }>>('product.photos')"
          @photo-click="photoIdForDialog = $event.id!"
        />

        <ProductPhotosDialog
          :is-open="photoIdForDialog !== null"
          :product="product"
          :initial-photo-id="photoIdForDialog!"
          image-fit="contain"
          @update:is-open="photoIdForDialog = null"
        />
      </QCardSection>

      <QSeparator />

      <ProductPacks
        v-model:product-packs="product.productPacks"
        :product="product"
        :parent-errors="errorsFor('product.productPacks')"
        @refresh="updateProduct(product.id)"
      />

      <QSeparator />

      <ProductStocks
        :product="product"
        @batch-changed="batchesRef.refresh()"
      />

      <QSeparator />

      <ProductBatches
        v-if="showBatches"
        ref="batchesRef"
        :product="product"
      />

      <QSeparator />

      <ProductShipments
        v-if="product.id"
        :product="product"
        type="CustomerOrder"
        :title="t('Orders')"
        storage-prefix="productCard.ordersList.report"
      />

      <QSeparator />

      <ProductShipments
        v-if="product.id"
        :product="product"
        type="SupplierReturn"
        :title="t('Returns')"
        storage-prefix="productCard.supplierReturnsList.report"
      />

      <QSeparator />

      <ProductStocksByDaysReport
        v-if="product.id"
        :product="product"
      />

      <QSeparator />

      <QCardActions>
        <QBtn
          exact
          icon="mdi-arrow-left"
          @click="navigateBack({ name: ROUTES.PRODUCTS_LIST })"
        >
          {{ t('Back') }}
        </QBtn>

        <QSpace />

        <ProductsPrintDialog
          :products="[product]"
        />

        <QBtn
          v-if="product.deletedAt === null"
          icon="mdi-delete"
          :disable="validating || !canBeDeleted"
          :loading="progress.deleting"
          color="red"
          :title="deleteButtonTitle"
          @click="deleteProduct()"
        >
          {{ t('Delete') }}
        </QBtn>
        <QBtn
          v-else
          icon="mdi-delete-restore"
          color="success"
          :loading="progress.restoring"
          @click="restoreProduct()"
        >
          {{ t('Restore') }}
        </QBtn>

        <QBtn
          color="success"
          icon="mdi-content-save"
          :loading="progress.applying"
          @click="save()"
        >
          {{ t('Apply') }}
        </QBtn>
        <QBtn
          color="primary"
          icon="mdi-content-save"
          :loading="progress.saving"
          @click="saveAndGoToList()"
        >
          {{ t('Save') }}
        </QBtn>
      </QCardActions>
    </QCard>
  </QPage>
</template>

<script setup lang="ts">

import BaseAlert from '@/components/BaseAlert.vue';
import BigLoading from '@/components/BigLoading.vue';
import CardTitle from '@/components/CardTitle.vue';
import ProductPhotosDialog from '@/components/ProductPhotosDialog.vue';
import SingleUnitDuration from '@/components/SingleUnitDuration.vue';
import useErrorHandling from '@/composables/useErrorHandling';
import useLocalizedFormatters from '@/composables/useLocalizedFormatters';
import useNavHelpers from '@/composables/useNavHelpers';
import useValidationRules from '@/composables/useValidationRules';
import ProductPackForDesktopEdit from '@/graphql/fragments/ProductPackForDesktopEdit';
import getProductCategoriesForSelect from '@/graphql/shorthands/getProductCategoriesForSelect';
import getSuppliersForSelect from '@/graphql/shorthands/getSuppliersForSelect';
import type {
  AccountingModel,
  EntityPhoto,
  EntityPhotoInput,
  MutationDeleteProductArgs,
  MutationRestoreProductArgs,
  MutationSaveProductArgs,
  Product,
  ProductInput,
  QueryValidateDeletionProductArgs,
  Scalars,
  ValidateDeletionProductResult,
} from '@/graphql/types';
import ROUTES from '@/router/routeNames';
import type { MaybeID, PhotoForm } from '@/types';
import ProductShipments from '@/views/Products/ProductEdit/ProductShipments.vue';
import ProductStocksByDaysReport from '@/views/Products/ProductEdit/ProductStocksByDaysReport.vue';
import ProductsPrintDialog from '@/views/Products/ProductsPrintDialog.vue';
import { gql, useClientHandle, useQuery } from '@urql/vue';
import type { QSelectOption } from 'quasar';
import { computed, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import PhotosForm from './ProductEdit/PhotosForm.vue';
import ProductBatches from './ProductEdit/ProductBatches.vue';
import ProductPacks from './ProductEdit/ProductPacks.vue';
import ProductStocks from './ProductEdit/ProductStocks.vue';

const { navigateBack } = useNavHelpers();

const { t } = useI18n();

const { formatDate } = useLocalizedFormatters();

const { client: urql } = useClientHandle();

// noinspection LocalVariableNamingConventionJS
const {
  fillErrorsFromGraphQLError,
  clearErrors,
  PrimaryErrorBanner,
  errorsFor,
  qErrorsFor,
} = useErrorHandling();

const progress = reactive({
  saving:  false,
  applying:  false,
  deleting:  false,
  restoring: false,
});

const { positiveIntegerOrNullRule, notEmptyRule } = useValidationRules();

const props = defineProps<{
  id: Scalars['ID'];
}>();

const batchesRef = ref();

const notFound = ref(false);

const { data, error, fetching, executeQuery } = useQuery<{ product: Product }>({
  query: gql`
    ${ProductPackForDesktopEdit}
    query GetProductForDesktopEdit($id: ID!) { product(id: $id) {
      id
      sku
      name
      baseName
      category { id name }
      primarySupplier { id name }
      shelfLife
      dontPrintName
      extraDataForPrinting
      accountingModel {
        id
        name
        byBatch
        byShelfLife
        batchNumberRequired
        batchProductionDateRequired
        batchExpirationDateRequired
      }
      photos { id url(size: SMALL) }
      mostBasicProductPack {
        id
        barcodes { barcode group isGenerated }
        lastScannedBarcode { barcode group isGenerated }
        measurementUnit { id name shortName isFractional }
        product {
          id
          sku
        }
      }
      productPacks {
        ...ProductPackForDesktopEdit
      }
      deletedAt
    } }
  `,
  variables: computed(() => ({ id: props.id })),
});
watch(error, fillErrorsFromGraphQLError);
watch(data, data => {
  if (data?.product) {
    product.value = data.product;
  } else {
    notFound.value = true;
  }
});
const product = ref<Product>({
  id:                   '',
  createdAt:            '',
  sku:                  '',
  name:                 '',
  shelfLife:            null,
  accountingModel:      {
    id:   '',
    name: '',
  } as AccountingModel,
  temperatureRegime:    null!,
  useSerialNumbers:     false,
  mostBasicProductPack: null!,
  productPacks:         [],
  photos:               [],
});

const router = useRouter();

async function updateProduct(id: Scalars['ID']) {
  // eslint-disable-next-line unicorn/prefer-ternary
  if (id === props.id) {
    await executeQuery();
  } else {
    await router.replace({
      name:   ROUTES.PRODUCTS_EDIT,
      params: { id },
    });
  }
}

watch(product, product => {
  supplier.value = {
    label: product.primarySupplier?.name ?? '',
    value: product.primarySupplier?.id ?? null,
  };
  category.value = {
    label: product.category?.name ?? '',
    value: product.category?.id ?? null,
  };
});

const supplier = ref<QSelectOption<MaybeID>>({
  value: null,
  label: '',
});

const suppliers = ref<QSelectOption[]>([]);

async function suppliersFilter(
  searchString: string,
  update: (cb: () => void) => void,
) {
  const s = await getSuppliersForSelect(searchString);
  update(() => {
    suppliers.value = s;
  });
}

const category = ref<QSelectOption<MaybeID>>({
  value: null,
  label: '',
});

const categories = ref<QSelectOption[]>([]);

async function categoriesFilter(
  searchString: string,
  update: (cb: () => void) => void,
) {
  const c = await getProductCategoriesForSelect(searchString);
  update(() => {
    categories.value = c;
  });
}

const {
  data:     accModelsResult,
  fetching: accountingModelsLoading,
} = useQuery<{ models: AccountingModel[] }>({
  query: gql`query GetAccountingModels { models: accountingModels { id name byBatch byShelfLife} }`,
});
const accModels = computed<AccountingModel[]>(() => accModelsResult.value?.models ?? []);

const {
  data:     validationResult,
  fetching: validating,
} = useQuery<{
  validateDeletionProduct: ValidateDeletionProductResult;
}, QueryValidateDeletionProductArgs>({
  query:     gql`
    query ValidateCanDeleteProduct($id: ID!) { validateDeletionProduct(id: $id) {
      hasNotCompletedShipments
      hasNotCompletedSupplies
      hasStocks
    } }
  `,
  variables: computed(() => ({ id: product.value.id })),
  pause:     computed(() => !product.value.id),
});
const hasStocks = computed((): boolean =>
  !!validationResult.value?.validateDeletionProduct.hasStocks);
const hasNotCompletedSupplies = computed((): boolean =>
  !!validationResult.value?.validateDeletionProduct.hasNotCompletedSupplies);
const hasNotCompletedShipments = computed((): boolean =>
  !!validationResult.value?.validateDeletionProduct.hasNotCompletedShipments);

const productPhotos = ref<PhotoForm[]>([]);

watch(product, function productChanged(): void {
  const photoToPhotoForm = (p: EntityPhoto): PhotoForm => ({
    id:     p.id,
    data:   null,
    url:    p.url,
    delete: false,
  });

  productPhotos.value = product.value.photos.map(photoToPhotoForm);
}, { immediate: true });

async function saveAndGoToList(): Promise<void> {
  progress.saving = true;

  if (await doSave()) {
    // noinspection ES6MissingAwait
    navigateBack({ name: ROUTES.PRODUCTS_LIST });
  }

  progress.saving = false;
}

async function save(): Promise<void> {
  progress.applying = true;

  const product = await doSave();

  if (product) {
    updateProduct(product.id);
  }

  progress.applying = false;
}

const deleteButtonTitle = computed(() => {
  if (hasStocks.value) {
    return t('Product with stocks cannot be deleted');
  }

  if (hasNotCompletedSupplies.value) {
    return t('Product with not completed supplies cannot be deleted');
  }

  if (hasNotCompletedShipments.value) {
    return t('Product with not completed orders cannot be deleted');
  }

  return t('Delete');
});

async function deleteProduct(): Promise<void> {
  progress.deleting = true;

  const { error } = await urql.mutation<unknown, MutationDeleteProductArgs>(
    gql`mutation DeleteProduct($id: ID!) { deleteProduct(id: $id) }`,
    { id: product.value.id },
  );

  if (error) {
    fillErrorsFromGraphQLError(error);
  }

  progress.deleting = false;

  navigateBack({ name: ROUTES.PRODUCTS_LIST });
}

async function restoreProduct(): Promise<void> {
  progress.restoring = true;

  const { data, error } = await urql.mutation<{ product: Product }, MutationRestoreProductArgs>(
    gql`
      mutation RestoreProduct($id: ID!) {
        product: restoreProduct(id: $id) { id }
      }
    `,
    { id: product.value.id },
  );

  progress.restoring = false;

  if (error) {
    fillErrorsFromGraphQLError(error);
  } else {
    await updateProduct(data!.product.id);
  }
}

function getSaveVariables(): MutationSaveProductArgs {
  const photoFormToPhotoInput = (photo: PhotoForm): EntityPhotoInput => ({
    id:     photo.id,
    data:   photo.data,
    delete: photo.delete,
  });

  return {
    id:      product.value.id,
    product: {
      name:                 product.value.name,
      sku:                  product.value.sku,
      supplierId:           supplier.value.value,
      categoryId:           category.value.value,
      shelfLife:            product.value.shelfLife || null,
      dontPrintName:        product.value.dontPrintName,
      extraDataForPrinting: product.value.extraDataForPrinting,
      accountingModelId:    product.value.accountingModel.id,
      productPacks:         [],
      photos: productPhotos.value.map(photoFormToPhotoInput),
    } as ProductInput,
  };
}

async function doSave(): Promise<Product | null> {
  clearErrors();

  const { data, error } = await urql.mutation<{ product: Product }, MutationSaveProductArgs>(
    gql`
      mutation SaveProduct($id: ID!, $product: ProductInput!) {
            product: saveProduct(id: $id, product: $product) { id }
          }
    `,
    getSaveVariables(),
  );

  if (error) {
    fillErrorsFromGraphQLError(error);
    return null;
  }

  return data!.product;
}

const showBatches = computed((): boolean => {
  return product.value.accountingModel.byBatch;
});

const canBeDeleted = computed((): boolean => {
  return !hasNotCompletedSupplies.value
    && !hasStocks.value
    && !hasNotCompletedShipments.value;
});

const photoIdForDialog = ref<Scalars['ID'] | null>(null);

</script>

<i18n lang="yaml">
ru:
  Go to Products list: Перейти к списку товаров
  Product not found: Товар не найден
  Category: Группа товаров
  Nomenclature: Номенклатура
  Shelf life (days): Срок годности (дни)
  Dont print Name: Этикетка без наименования
  Extra Data for Printing: Поле для этикетки
  Photos: Фотографии
  Returns: Возвраты
  Product with stocks cannot be deleted: >
    Нельзя удалить товар, для которого есть остатки
  Product with not completed supplies cannot be deleted: >
    Нельзя удалить товар, для которого есть незавершенные поступления
  Product with not completed orders cannot be deleted: >
    Нельзя удалить товар, для которого есть незавершённые заказы

en:
  Go to Products list: Go to Products list
  Product not found: Product not found
  Category: Category
  Nomenclature: Nomenclature
  Shelf life (days): Shelf life (days)
  Dont print Name: Don't print Name
  Extra Data for Printing: Extra Data for Printing
  Photos: Photos
  Returns: Returns
  Product with stocks cannot be deleted: Product with stocks cannot be deleted
  Product with not completed supplies cannot be deleted: >
    Product with not completed supplies cannot be deleted
  Product with not completed orders cannot be deleted: >
    Product with not completed orders cannot be deleted
</i18n>
