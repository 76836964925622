<script setup lang="ts">
import useClipboard from '@/composables/useClipboard';
import type { Barcode } from '@/graphql/types';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const { barcode, highlight = false, copyOnClick = false } = defineProps<{
  barcode: Barcode;
  highlight?: boolean;
  copyOnClick?: boolean;
}>();

const { copy } = useClipboard();

const tooltip = computed(
  () => copyOnClick ? t('Click to copy to the clipboard') : undefined
);

function handleClick() {
  if (copyOnClick) {
    copy(barcode.barcode, t('Barcode is copied to the clipboard'));
  }
}
</script>

<template>
  <QChip
    :color="highlight ? 'primary' : 'grey-4'"
    :text-color="highlight ? 'white' : 'black'"
    :class="{'with-group': !!barcode.group }"
    :clickable="copyOnClick"
    :title="tooltip"
    @click="handleClick"
  >
    <QChip
      v-if="barcode.group"
      color="positive"
      text-color="white"
      :label="barcode.group"
      :size="$attrs.size as (string | undefined)"
    />
    <QIcon
      v-if="barcode.isGenerated"
      name="mdi-dice-3-outline"
      class="q-mr-xs"
      size="22px"
    />
    {{ barcode.barcode }}
    <slot />
  </QChip>
</template>

<style scoped lang="sass">
.q-chip.with-group
  padding-left: 0

  & > :deep(.q-chip__content > .q-chip:first-child)
    margin-left: 0
    padding-right: 0.5em
    border-top-right-radius: 0
    border-bottom-right-radius: 0
</style>

<i18n lang="yaml">
ru:
  Click to copy to the clipboard: Нажмите, чтобы скопировать в буфер обмена
  Barcode is copied to the clipboard: Штрихкод скопирован в буфер обмена
en:
  Click to copy to the clipboard: Click to copy to the clipboard
  Barcode is copied to the clipboard: Barcode is copied to the clipboard
</i18n>
