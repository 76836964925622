<template>
  <QPage padding>
    <QCard>
      <PrimaryErrorBanner />
      <QCardSection class="row">
        <QInput
          v-model="v.prefix.$model"
          :loading="fetching"
          :disable="fetching"
          :label="t('Weight Barcodes Prefix')"
          class="col-12 col-sm-7 col-md-4 col-lg-3"
          clearable
          maxlength="2"
          :error="v.prefix.$error"
          :error-message="v.prefix.$errors[0]?.$message as string"
          no-error-icon
          @blur="v.$validate()"
        />
      </QCardSection>

      <QSeparator />

      <QCardActions>
        <QBtn
          icon="mdi-arrow-left"
          :label="t('Back')"
          @click="navigateBack({ name: ROUTES.WAREHOUSE_SETTINGS })"
        />

        <QSpace />

        <QBtn
          :label="t('Apply')"
          color="success"
          icon="mdi-content-save"
          :loading="saving && !goToListAfterSave"
          :disable="fetching || saving || v.$invalid"
          @click="goToListAfterSave = false; save()"
        />

        <QBtn
          :label="t('Save')"
          color="primary"
          icon="mdi-content-save"
          :loading="saving && goToListAfterSave"
          :disable="fetching || saving || v.$invalid"
          @click="goToListAfterSave = true; save()"
        />
      </QCardActions>
    </QCard>
  </QPage>
</template>

<script setup lang="ts">

import useBreadcrumbs from '@/composables/useBreadcrumbs';
import useErrorHandling from '@/composables/useErrorHandling';
import useNavHelpers from '@/composables/useNavHelpers';
import type { MutationSaveProductsSettingsArgs, Warehouse } from '@/graphql/types';
import ROUTES from '@/router/routeNames';
import { gql, useMutation, useQuery } from '@urql/vue';
import useVuelidate from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';
import { reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { navigateBack } = useNavHelpers();

const { fillErrorsFromGraphQLError, clearErrors, PrimaryErrorBanner } = useErrorHandling();

const { t } = useI18n();

useBreadcrumbs(t('Products Settings'));

const v = useVuelidate({
  prefix: [helpers.withMessage(
    t('Two digits expected'),
    v => !v || typeof v === 'string' && /^\d{2}$/.test(v),
  )],
}, reactive({ prefix: '' }), {
  $rewardEarly: true,
});

const { data, fetching } = useQuery<{ warehouse: Warehouse }>({
  query: gql`
    query GetWarehouseForProductsSettings {
      warehouse {
        id
        weightBarcodesPrefix
      }
    }
  `,
});

watch(data, data => {
  if (data) {
    v.value.prefix.$model = data.warehouse.weightBarcodesPrefix ?? '';
  }
});

const {
  executeMutation: doSave,
  fetching: saving,
} = useMutation<unknown, MutationSaveProductsSettingsArgs>(
  gql`
    mutation SaveProductsSettings($weightBarcodesPrefix: String) {
      saveProductsSettings(weightBarcodesPrefix: $weightBarcodesPrefix) {
        weightBarcodesPrefix
      }
    }
  `,
);

const goToListAfterSave = ref(false);

async function save() {
  clearErrors();
  const { error } = await doSave({
    weightBarcodesPrefix: v.value.prefix.$model,
  });

  if (error) {
    fillErrorsFromGraphQLError(error);
    return;
  }

  if (goToListAfterSave.value) {
    navigateBack({ name: ROUTES.WAREHOUSE_SETTINGS });
  }
}

</script>

<i18n lang="yaml">
ru:
  Warehouse Settings: Настройки склада
  Products Settings: Настройки товаров
  Weight Barcodes Prefix: Префикс штрихкодов для весовых товаров
  Two digits expected: Ожидается строка из двух цифр
en:
  Warehouse Settings: Warehouse Settings
  Products Settings: Products Settings
  Weight Barcodes Prefix: Weight Barcodes Prefix
  Two digits expected: Two digits expected
</i18n>
