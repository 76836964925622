<template>
  <MaximizedDialog
    :model-value="modelValue"
    :title="t('Cluster Selection')"
    transition-show="slide-up"
    transition-hide="slide-down"
    @close="handleClose"
  >
    <QCard class="column no-wrap scroll-y">
      <div class="q-mt-lg q-ma-sm">
        <span class="text-weight-bold">
          {{ t('Containers for Selection') }}
        </span>
      </div>

      <QList
        class="col"
        bordered
        separator
      >
        <QItem
          v-for="item in itemsForScan"
          :key="item.shipment.id"
          clickable
          class="list-item-fixed-height"
          :active="item.shipment.id === editingItem?.shipment.id"
          @click="handleItemClick(item)"
        >
          <QItemSection
            class="dense-labels no-wrap"
            top
          >
            <QItemLabel class="row justify-between">
              <span>{{ t('# {text}', { text: item.shipment.externalId }) }}</span>
              <span>{{ item.shipment.customer?.name }}</span>
            </QItemLabel>
            <QItemLabel class="row justify-between">
              <span>
                {{ t('{n} products', item.shipment.items.length) }}
                /
                {{ formatWeight(calculateWeight(item.shipment)) }}
                /
                {{ formatVolume(calculateVolumeForShipment(item.shipment)) }}
              </span>
              <span>{{ item.shipment.carrier?.name }}</span>
            </QItemLabel>
            <QItemLabel class="row justify-between">
              <GraphQLQueryScanField
                v-if="editingItem && editingItem.shipment.id === item.shipment.id"
                :initial-value="editingItem.container?.barcode"
                :loading="fillingContainer"
                class="full-width selected"
                :query="containerQuery"
                :placeholder="t('Container')"
                :rules="[containerMustBeEmptyRule, containerShouldBeUniqueRule(item)]"
                :not-found-message="t('Container not found')"
                @scan="handleContainerScan"
              />
              <template v-else-if="item.container">
                <span>{{ item.container.barcode }}</span>
                <span>{{ item.container.name }}</span>
              </template>
            </QItemLabel>
          </QItemSection>
        </QItem>
      </QList>

      <PrimaryErrorBanner />

      <CreateContainer @create="handleContainerScan($event)">
        <template #activator="{ onClick }">
          <ButtonsRow v-slot="{ buttonProps }">
            <QBtn
              v-bind="buttonProps"
              icon="mdi-autorenew"
              class="col-4"
              @click="clearSelectionOrdersContainers()"
            >
              {{ t('Again') }}
            </QBtn>
            <QBtn
              v-bind="buttonProps"
              icon="mdi-view-grid-plus-outline"
              class="col-4"
              @click="onClick()"
            >
              {{ t('Create Container') }}
            </QBtn>
          </ButtonsRow>
        </template>
      </CreateContainer>
    </QCard>
  </MaximizedDialog>
</template>

<script setup lang="ts">
import useLocalizedFormatters from '@/composables/useLocalizedFormatters';
import type {
  Container,
  Maybe,
  MutationFillTransferTaskItemsContainerArgs,
  Shipment,
  Storage,
  TransferTask,
  TransferTaskItem,
} from '@/graphql/types';
import { useI18n } from 'vue-i18n';
import useBreadcrumbs from '@/composables/useBreadcrumbs';
import { ref } from 'vue';
import CreateContainer from '@/views/Mobile/CreateContainer.vue';
import GraphQLQueryScanField from '@/components/Mobile/GraphQLQueryScanField.vue';
import { gql, useMutation } from '@urql/vue';
import ButtonsRow from '@/components/Mobile/ButtonsRow.vue';
import { calculateVolumeForShipment, calculateWeight } from '@/helpers/shipments';
import type { FunctionValidationRule } from '@/types';
import useSpeaker from '@/composables/useSpeaker';
import useErrorHandling from '@/composables/useErrorHandling';
import * as R from 'ramda';
import MaximizedDialog from '@/components/MaximizedDialog.vue';
import TransferTaskForDashboardList from '@/graphql/fragments/TransferTaskForDashboardList';

const { t } = useI18n();

const { formatWeight, formatVolume } = useLocalizedFormatters();

useBreadcrumbs(t('Cluster Selection'));

const { fillErrorsFromGraphQLError, clearErrors, PrimaryErrorBanner } = useErrorHandling();

const props = defineProps<{
  task: TransferTask;
  modelValue: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
  (e: 'changed', task: TransferTask): void;
  (e: 'fullyFilled', task: TransferTask): void;
}>();

type ShipmentContainer = { shipment: Shipment; container: Maybe<Storage> };

const itemsForScan = ref<ShipmentContainer[]>(
  R.pipe(
    R.map((i: TransferTaskItem) => ({
      shipment: i.shipment!,
      container: i.storageTo || null,
    })),
    R.uniqBy(i => i.shipment.id),
  )(props.task.items),
);

// eslint-disable-next-line vue/no-ref-object-reactivity-loss
const editingItem = ref<ShipmentContainer | null>(itemsForScan.value[0]);

function handleItemClick(item: ShipmentContainer): void {
  editingItem.value = item;
}

const containerQuery = gql`
  query GetContainerForTransferSelection($barcode: String!) {
    containerByBarcode(barcode: $barcode) {
      id
      name
      barcode
      isEmpty
      activeShipments {
        id
      }
      itemsForShipping {
        id
        shipment {
          id
        }
      }
    }
  }
`;

const speaker = useSpeaker();

async function handleContainerScan(container: Container): Promise<void> {
  clearErrors();

  speaker.speak(container.name);

  const { data, error } = await fillContainerForTaskItem({
    transferTaskId: props.task.id,
    shipmentContainers: [{ containerId: container.id, shipmentId: editingItem.value!.shipment.id }],
  });

  if (error) {
    fillErrorsFromGraphQLError(error);
    return;
  }

  itemsForScan.value.find(i => i.shipment.id === editingItem.value!.shipment.id)!.container =
    container;

  emit('changed', data!.task);

  const nextEditingItem = itemsForScan.value.find(so => so.container === null);

  if (nextEditingItem) {
    editingItem.value = nextEditingItem;
  } else {
    editingItem.value = null;
    emit('fullyFilled', data!.task);
  }
}

async function clearSelectionOrdersContainers(): Promise<void> {
  clearErrors();
  itemsForScan.value = itemsForScan.value.map(i => ({
    ...i,
    container: null,
  }));
  editingItem.value = itemsForScan.value[0];

  const { data, error } = await fillContainerForTaskItem({
    transferTaskId: props.task.id,
    shipmentContainers: itemsForScan.value.map(i => ({
      shipmentId: i.shipment.id,
      containerId: null,
    })),
  });

  if (error) {
    fillErrorsFromGraphQLError(error);
    return;
  }

  emit('changed', data!.task);
}

const { executeMutation: fillContainerForTaskItem, fetching: fillingContainer } = useMutation<
  { task: TransferTask },
  MutationFillTransferTaskItemsContainerArgs
>(gql`
  mutation FillTransferTaskItemsContainer(
    $transferTaskId: ID!
    $shipmentContainers: [ShipmentContainerInput!]!
  ) {
    task: fillTransferTaskItemsContainer(
      transferTaskId: $transferTaskId
      shipmentContainers: $shipmentContainers
    ) {
      ...TransferTaskForDashboardList
    }
  }
  ${TransferTaskForDashboardList}
`);

function containerMustBeEmptyRule(storage: Container): ReturnType<FunctionValidationRule> {
  if (storage.itemsForShipping.every(i => i.shipment.id === editingItem.value!.shipment.id)) {
    return true;
  }

  if (!storage.isEmpty) {
    return t('Container is occupied. Start {operation} in empty Container', {
      operation: t('Selection'),
    });
  }
  return true;
}

function containerShouldBeUniqueRule(item: ShipmentContainer): FunctionValidationRule<Container> {
  return (container: Container) => {
    if (
      itemsForScan.value.some(
        i => i.container?.id === container.id && i.shipment.id !== item.shipment.id,
      )
    ) {
      return t('Container already used by another order');
    }

    return true;
  };
}

function handleClose() {
  clearErrors();
  emit('update:modelValue', false);
}
</script>

<style lang="scss">
.list-item-fixed-height {
  height: 85px;
}
</style>

<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/scanning.yaml"></i18n>
<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/selection.yaml"></i18n>
<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/counts.yaml"></i18n>

<i18n lang="yaml">
ru:
  Containers for Selection: Контейнеры для отбора
  Container already used by another order: Контейнер уже используется для другого заказа
  Create Container: Создать контейнер
  Again: Заново

en:
  Containers for Selection: Containers for Selection
  Container already used by another order: Container already used by another order
  Create Container: Create Container
  Again: Again
</i18n>
