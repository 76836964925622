<template>
  <QPage padding>
    <PrimaryErrorBanner />
    <BigLoading v-if="fetchingShipment" />
    <QCard v-else-if="shipment">
      <CardTitle>
        <slot
          name="title"
          :shipment="shipment"
        />
        <QChip
          class="ml-2"
          :color="badgeColors.forShipmentState(shipment)"
          text-color="black"
        >
          {{ t('shipmentState.' + shipment.state) }}
        </QChip>
      </CardTitle>

      <QCardSection>
        <div class="row q-col-gutter-md">
          <div class="col-12 col-sm-3 col-md-3 col-lg-1">
            <QInput
              readonly
              :label="t('ID')"
              :model-value="shipment.id"
            />
          </div>
          <div class="col-12 col-sm-3 col-md-3 col-lg-1">
            <QInput
              readonly
              :label="t('Created at')"
              :model-value="formatDate(shipment.createdAt)"
            />
          </div>
          <slot
            name="fields"
            :shipment="shipment"
          />
          <div
            v-if="shipment.state !== ShipmentStateEnum.COMPLETED"
            class="col-12 col-sm-6 col-md-4 col-lg-2"
          >
            <ShipmentEditDesktopContainersField
              :order-id="id"
              :max-to-show="3"
            />
          </div>
          <div
            class="col-12 col-sm-6 col-md-4 col-lg-2"
          >
            <QInput
              v-model.number="shipment.priority"
              :label="t('Priority')"
              type="number"
              step="10"
              :rules="[notEmptyRule]"
              v-bind="qErrorsFor('priority')"
            />
          </div>
          <div
            class="col-12 col-md-6 col-lg-3"
          >
            <LoadingLinksField
              :query="selectionsQueryArgs"
              :label="t('Selection')"
            >
              <template #item="{item}">
                <RouterLink :to="{ name: ROUTES.SELECTIONS_EDIT, params: { id: String(item.id) } }">
                  {{ item.id }}
                </RouterLink>
                <OperationState
                  :operation="item"
                  dense
                  class="q-my-none q-mr-none"
                />
              </template>
            </LoadingLinksField>
          </div>
        </div>
      </QCardSection>

      <QCardSection>
        <GraphQLQueryTable
          :fields="itemsTableHeaders"
          :graphql-query="queryItems"
          :available-filters="availableFilters"
          :fixed-filters="fixedFilters"
          :row-is-deleted="item => item.productPack.deletedAt !== null"
          storage-prefix="shipmentOrderEdit.itemsList"
          :keep-search-string="false"
          show-line-numbers
        >
          <template #body-cell-sku="column">
            <BodyCellLink
              :column="column"
              :to="{ name: ROUTES.PRODUCTS_EDIT, params: { id: column.row.productPack.product.id } }"
              variant="link"
              no-wrap
            />
          </template>
          <template #batch-actions="{ rows, clear }: { rows: ShipmentItemsReportRow[]; clear: () => void }">
            <SimpleTableBatchActions :rows-count="rows.length">
              <ProductsPrintDialog
                :products="rows.map(item => item.productPack.product)"
                :document="shipment"
                @done="clear"
              />
            </SimpleTableBatchActions>
          </template>
        </GraphQLQueryTable>
      </QCardSection>

      <QSeparator />

      <QCardActions>
        <QBtn
          exact
          icon="mdi-arrow-left"
          @click="navigateBack({ name: ROUTES.CUSTOMER_ORDERS_LIST })"
        >
          {{ t('Back') }}
        </QBtn>

        <QSpace />

        <slot
          name="buttons"
          :shipment="shipment"
          @refresh="fetchOrder()"
        />
        <ShipmentCompleteDialog
          v-if="shipment.state !== ShipmentStateEnum.COMPLETED"
          :shipment="shipment"
          @completed="fetchOrder"
        />
        <QBtn
          color="success"
          icon="mdi-content-save"
          :loading="saving"
          @click="save(shipment)"
        >
          {{ t('Apply') }}
        </QBtn>
        <QBtn
          color="primary"
          icon="mdi-content-save"
          :loading="saving"
          @click="saveAndGoToList(shipment)"
        >
          {{ t('Save') }}
        </QBtn>
      </QCardActions>
    </QCard>
    <slot
      v-else
      name="not-found-banner"
    />
  </QPage>
</template>

<script setup lang="ts">

import BodyCellLink from '@/components/BaseTable/BodyCellLink.vue';
import BigLoading from '@/components/BigLoading.vue';
import CardTitle from '@/components/CardTitle.vue';
import GraphQLQueryTable from '@/components/GraphQLQueryTable.vue';
import useErrorHandling from '@/composables/useErrorHandling';
import useLocalizedFormatters from '@/composables/useLocalizedFormatters';
import useNavHelpers from '@/composables/useNavHelpers';
import useValidationRules from '@/composables/useValidationRules';
import getSuppliersForSelect from '@/graphql/shorthands/getSuppliersForSelect';
import type {
  CustomerOrder,
  ProductPack,
  QueryShipmentArgs,
  ReportFilterInput,
  Scalars,
  Shipment,
  ShipmentItemsReportRow,
  Supplier,
  SupplierReturn,
} from '@/graphql/types';
import { ShipmentStateEnum } from '@/graphql/types';
import * as badgeColors from '@/helpers/badgeColors';
import * as reports from '@/helpers/reports';
import { createBoolean } from '@/helpers/reports';
import ROUTES from '@/router/routeNames';
import type { ReportFilter, TableColumn } from '@/types/reports';
import ProductsPrintDialog from '@/views/Products/ProductsPrintDialog.vue';
import ShipmentCompleteDialog from '@/views/Shipping/Shipments/ShipmentCompleteDialog.vue';
import SimpleTableBatchActions from '@/views/SimpleTableBatchActions.vue';
import { gql, useMutation, useQuery } from '@urql/vue';
import { logicNot, whenever } from '@vueuse/core';
import type { DocumentNode } from 'graphql';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import LoadingLinksField from '@/components/LoadingLinksField.vue';
import OperationState from '@/components/OperationState.vue';
import ShipmentEditDesktopContainersField
  from '@/views/Shipping/Shipments/ShipmentEditDesktopContainersField.vue';

const { navigateBack } = useNavHelpers();

const { t } = useI18n();

const { notEmptyRule } = useValidationRules();

// noinspection LocalVariableNamingConventionJS
const { fillErrorsFromGraphQLError, clearErrors, qErrorsFor, PrimaryErrorBanner } = useErrorHandling();

const { formatDate } = useLocalizedFormatters();

const props = defineProps<{
  id: Scalars['ID'];
  saveMutation: DocumentNode;
  saveArgs: (shipment: Shipment) => object; // todo
}>();

const emit = defineEmits<{
  load: [shipment: Shipment];
}>();

const selectionsQueryArgs = computed(() => ({
  query:     gql`
    query GetSelectionsByShipmentForShipmentCardDesktop($shipmentId: ID!) {
      items: selectionsByShipment(shipmentId: $shipmentId) { __typename id state }
    }
  `,
  variables: { shipmentId: props.id },
}));

const {
  data:         shipmentData,
  fetching:     fetchingShipment,
  error:        shipmentError,
  executeQuery: fetchOrder
} = useQuery<{ shipment: Shipment }, QueryShipmentArgs>({
  query:     gql`
    query GetShipment($id: ID!) {
      shipment(id: $id) {
        id
        externalId
        createdAt
        plannedShipmentDate
        counterparty { id name }
        carrier { id name }
        state
        items {
          id
          productPack { id }
          amount
          shippedAmount
        }
        ... on SupplierReturn {
          id
          supplyNumber
          supply { id externalId }
        }
        priority
      }
    }
  `,
  variables: computed(() => ({
    id: props.id,
  })),
});

watch(shipmentError, fillErrorsFromGraphQLError);

whenever(logicNot(fetchingShipment), () => {
  shipment.value = shipmentData.value?.shipment ?? null;
  emit('load', shipment.value!);
});

const shipment = ref<Shipment | null>(null);

const itemsTableHeaders: TableColumn<ShipmentItemsReportRow>[] = [
  {
    label:  t('SKU'),
    name:   'sku',
    field:  'productPack',
    format: (pack?: ProductPack) => pack?.product.sku ?? '',
    align:  'left',
  },
  {
    label: t('Name'),
    name:  'productPack.product.name',
    field:  'productPack',
    format: (pack?: ProductPack) => pack?.product.name ?? '',
    align: 'left',
  },
  {
    label:  t('Supplier'),
    name:   'supplier',
    field:  i => i.productPack.product.primarySupplier,
    format: (supplier?: Supplier) => supplier?.name ?? '',
    align:  'left',
  },
  {
    label: t('Amount'),
    name:  'itemAmount',
    field: i => i.amount || '',
    summaryField: true,
    requestFields: ['productPack', 'amount'],
  },
  {
    label: t('Selected'),
    name:  'selectedAmount',
    field: i => ((i.selectedAmount ?? 0) - (i.shippedAmount ?? 0)) || '',
    summaryField: true,
    requestFields: ['productPack', 'selectedAmount', 'shippedAmount'],
  },
  {
    label: t('Reserved'),
    name:  'reservedAmount',
    field: i => ((i.reservedAmount ?? 0) - (i.selectedAmount ?? 0)) || '',
    summaryField: true,
    requestFields: ['productPack', 'reservedAmount', 'amountInSelections'],
  },
  {
    label: t('Shipped'),
    name:  'shippedAmount',
    field: i => (i.shippedAmount ?? 0) || '',
    summaryField: true,
    requestFields: ['productPack', 'shippedAmount'],
  },
  {
    label: t('Not Reserved'),
    name:  'notReservedAmount',
    field: i => (i.amount - (i.reservedAmount ?? 0)) || '',
    summaryField: true,
    requestFields: ['productPack', 'amount', 'reservedAmount'],
    headerClasses: 'text-no-wrap',
  },
  {
    label: t('Storage Amount'),
    name:  'amountInStorageArea',
    field: i => i.amountInStorageArea || '',
  },
];

const availableFilters: ReportFilter[] = [
  {
    field:     'isSelected',
    label:     t('Selected'),
    operators: [ createBoolean('=') ],
  },
  {
    field:                 'supplier',
    label:                 t('Supplier'),
    hideLabelWhenSelected: true,
    operators:             (['=', '!=', 'in', 'not in'] as const).map(op =>
      reports.createList(op, getSuppliersForSelect, t(`reportFilterOperator.${op}`)),
    )
  },
];

const fixedFilters = computed<ReportFilterInput[]>(() => ([{
  field:    'shipmentId',
  operator: '=',
  value:    JSON.stringify(shipment.value!.id),
}]));

const queryItems = gql`
  query GetShipmentItems(
    $page: Int,
    $perPage: Int!,
    $query: String,
    $filter: [ReportFilterInput!],
    $sort: [ReportSortInput!]!,
  ) {
    result: shipmentItemsReport(
      page: $page,
      perPage: $perPage,
      query: $query,
      filter: $filter,
      sort: $sort,
    ) {
      data {
        id
        amount
        amountInSelections
        shippedAmount
        selectedAmount
        reservedAmount
        amountInStorageArea
        productPack {
          id
          product {
            id
            sku
            name
            primarySupplier { id name }
            photos { id url(size: SMALL) }
            mostBasicProductPack {
              id
              smallerProductPack { id }
              minMeasurementUnit { id shortName }
              measurementUnit { id shortName }
              quantityInMinMeasurementUnits
              lastScannedBarcode { barcode group }
              barcodes { barcode group }
            }
            productPacks {
              id
              lastScannedBarcode { barcode group }
              barcodes { barcode group }
              measurementUnit { id }
            }
          }
          measurementUnit { id name shortName }
          quantity
          quantityInMinMeasurementUnits
          minMeasurementUnit { id shortName }
          smallerProductPack { id quantity }
          deletedAt
        }
      }
      total
      summary {
        amount
        selectedAmount
        shippedAmount
        reservedAmount
      }
    }
  }
`;

const {
  fetching: saving,
  error: saveError,
  executeMutation: doSave,
} = useMutation(props.saveMutation);

watch(saveError, fillErrorsFromGraphQLError);

async function save(shipment: CustomerOrder) {
  clearErrors();
  return doSave(props.saveArgs(shipment));
}

async function saveAndGoToList(shipment: CustomerOrder) {
  const { error } = await save(shipment);

  if (!error) {
    navigateBack(getBackRoute(shipment));
  }
}

function getBackRoute(shipment: CustomerOrder | SupplierReturn) {
  return shipment.__typename === 'CustomerOrder'
    ? { name: ROUTES.CUSTOMER_ORDERS_LIST }
    : { name: ROUTES.SUPPLIER_RETURNS_LIST };
}
</script>

<i18n lang="yaml">
ru:
  Order not found: Заказ не найден
  Go to Orders List: Перейти к списку заказов
  Storage Amount: Хранение

en:
  Order not found: Order not found
  Go to Orders List: Go to Orders List
  Storage Amount: Storage

</i18n>
