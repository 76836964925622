<template>
  <!--
  width: 100% нужен временно, пока слайд имеет класс inline-block
  и может использоваться как в ScrollCarousel, так и в SwiperCarousel.
  После полного перевода на SwiperCarousel
  убрать width: 100% здесь и inline-block у ProductSlide
  -->
  <ProductSlide
    :product-pack="transferItem.storageUnit.productPack"
    :actual-amount="takenAmount"
    :expected-amount="expectedAmountInMinUnits"
    :amount-badge-color="productSlideAmountBadgeColor"
    :total-amount="totalTakenAmount"
    :show-total-amount="totalTakenAmount !== takenAmountInMinUnits"
    title-clickable
    style="width: 100%"
    :deletable="!!slide.movement"
    @delete="emit('delete')"
    @title-click="emit('product-click', transferItem.storageUnit.productPack.product)"
    @amount-click="handleAmountClick"
  >
    <template
      v-if="transferItem.targetProductPack"
      #before-amount
    >
      <QChip
        dense
        color="purple-4"
        text-color="white"
        square
        clickable
        icon="mdi-arrow-right"
      >
        {{ expectedAmountInTargetUnits }}
        {{ transferItem.targetProductPack.measurementUnit.shortName }}
      </QChip>
    </template>
    <template
      v-if="store.isClusterSelection"
      #bottom
    >
      <QItem>
        <QItemSection>
          {{ t('Order') }}
          {{ slide.transferItems[0]?.shipment?.externalId }}
        </QItemSection>
        <QItemSection class="text-right">
          {{ slide.transferItems[0]?.storageTo.name }}
        </QItemSection>
      </QItem>
    </template>
    <template
      v-if="indicesWithProduct.length > 1"
      #under-amount
    >
      <CarouselIndicators
        :count="indicesWithProduct.length"
        :active="indicesWithProduct.indexOf(index)"
        :limit="5"
        :indicator-size="7"
      />
    </template>
  </ProductSlide>
</template>

<script setup lang="ts">
import CarouselIndicators from '@/components/Mobile/CarouselIndicators.vue';
import ProductSlide from '@/components/Mobile/ProductSlide.vue';
import type { Product } from '@/graphql/types';
import getPacksRatio from '@/helpers/getPacksRatio';
import useTransferProcessStore from '@/stores/transferProcess';
import type { TransferSlide } from '@/types/transfer';
import { isNotNil, uniqBy } from 'ramda';
import { roundTo } from 'round-to';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const store = useTransferProcessStore();

const { t } = useI18n();

const props = defineProps<{
  slide: TransferSlide;
  index: number;
}>();

const emit = defineEmits<{
  (e: 'delete'): void;
  (e: 'product-click', product: Product): void;
  (e: 'update:slide', slide: number): void;
}>();

const transferItem = computed(() => props.slide.transferItems[0]);

const expectedAmountInMinUnits = computed(
  () =>
    store.totalPlannedAmount(props.slide.transferItems)
    * transferItem.value.storageUnit.productPack.quantityInMinMeasurementUnits,
);

const productSlideAmountBadgeColor = computed((): string | undefined => {
  if (totalTakenAmount.value === expectedAmountInMinUnits.value) {
    return 'green-3';
  }

  if (totalTakenAmount.value > 0) {
    return 'yellow-3';
  }

  return undefined;
});

const itemsWithProduct = computed(() =>
  store.slides
    .map((item, index) => [item, index] as const)
    .filter(
      ([item]) =>
        item.transferItems[0].storageUnit.productPack.product.id
          === transferItem.value.storageUnit.productPack.product.id
        && (!store.isClusterSelection
          || item.transferItems[0].shipment?.id === transferItem.value.shipment?.id),
    ),
);

const indicesWithProduct = computed(() => itemsWithProduct.value.map(([, index]) => index));

function handleAmountClick() {
  const index =
    (indicesWithProduct.value.indexOf(props.index) + 1) % indicesWithProduct.value.length;

  emit('update:slide', indicesWithProduct.value[index]);
}

const expectedAmountInTargetUnits = computed(() => {
  if (!transferItem.value.targetProductPack) {
    return null;
  }
  const ratio = getPacksRatio(
    transferItem.value.storageUnit.productPack,
    transferItem.value.targetProductPack,
  );

  if (!ratio) {
    return null;
  }

  const [sourceQuantity, targetQuantity] = ratio;

  return roundTo(
    (store.totalPlannedAmount(props.slide.transferItems) * targetQuantity) / sourceQuantity,
    2,
  );
});

const takenAmount = computed(
  () => props.slide.movement?.amount ?? store.totalTransferredAmount(props.slide.transferItems),
);

const takenAmountInMinUnits = computed(() =>
  roundTo(
    takenAmount.value * transferItem.value.storageUnit.productPack.quantityInMinMeasurementUnits,
    3,
  ),
);

const totalTakenAmount = computed(() =>
  roundTo(
    uniqBy(
      m => m.id,
      itemsWithProduct.value.map(([item]) => item.movement).filter(isNotNil),
    ).reduce((acc, m) => acc + m.amount * m.storable.productPack.quantityInMinMeasurementUnits, 0),
    3,
  ),
);
</script>
