<template>
  <BigLoading v-if="initializing" />
  <QPage
    v-else
    class="column no-wrap scroll-y"
  >
    <MaximizedDialog
      :model-value="showingSupply"
      transition-show="slide-up"
      transition-hide="slide-down"
      :title="(store.supply as CustomerReturn | SupplierDelivery)?.__typename === 'CustomerReturn'
        ? t('Customer Return')
        : t('Supply')"
      fixed-header
      @close="showingSupply = false"
    >
      <SupplyDetailsCard
        :supply="store.supply!"
        no-header
      />
    </MaximizedDialog>
    <ProductArrivalHeader />
    <ArrivalScanStorage
      v-if="!store.storage"
      class="col"
      @back="exitArrival"
      @show-supply="showingSupply = true"
    />
    <ArrivalScanProduct
      v-else
      @exit="exitArrival"
      @show-supply="showingSupply = true"
    />
  </QPage>
</template>

<script setup lang="ts">

import BigLoading from '@/components/BigLoading.vue';
import MaximizedDialog from '@/components/MaximizedDialog.vue';
import useBreadcrumbs from '@/composables/useBreadcrumbs';
import useNavHelpers from '@/composables/useNavHelpers';
import useWakeLockWhenMounted from '@/composables/useWakeLockWhenMounted';
import type { CustomerReturn, SupplierDelivery } from '@/graphql/types';
import ROUTES from '@/router/routeNames';
import useProductArrivalStore from '@/stores/productArrival';
import ArrivalScanProduct from '@/views/Mobile/Arrival/ArrivalScanProduct.vue';
import ArrivalScanStorage from '@/views/Mobile/Arrival/ArrivalScanStorage.vue';
import ProductArrivalHeader from '@/views/Mobile/Arrival/ProductArrivalHeader.vue';
import SupplyDetailsCard from '@/views/Mobile/Arrival/SupplyDetailsCard.vue';
import { onBeforeMount, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

const { navigateBack } = useNavHelpers();

const { t } = useI18n();

useWakeLockWhenMounted();

useBreadcrumbs(t('Arrival'));

const props = defineProps<{
  id?: string;
  supplyId?: string;
}>();

const store = useProductArrivalStore();

const router = useRouter();

const initializing = ref(false);

onBeforeMount(async function created(): Promise<void> {
  initializing.value = true;

  // eslint-disable-next-line unicorn/prefer-ternary
  if (props.supplyId) {
    await store.initForSupply(props.supplyId);
  } else {
    await store.init(props.id ?? null);
  }

  initializing.value = false;
});

watch(() => store.currentArrival, arrival => {
  if (!props.id && arrival?.id) {
    router.replace({ name: ROUTES.ARRIVAL_PROCESS, params: { id: arrival.id } });
  }
});

const showingSupply = ref(false);

function exitArrival() {
  navigateBack({ name: ROUTES.ARRIVAL_DASHBOARD });
}

</script>

<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/scanning.yaml"></i18n>

<i18n lang="yaml">
ru:
  Arrival: Приёмка

en:
  Arrival: Arrival

</i18n>
