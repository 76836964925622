<template>
  <QCard>
    <CardTitle>
      <QAvatar
        square
        class="q-mr-md"
      >
        <QImg :src="moyskladLogo" />
      </QAvatar>
      {{ t('MoySklad') }}

      <QSkeleton
        v-if="loading"
        type="QToggle"
        height="24px"
        class="q-ml-md"
      />
      <QToggle
        v-else
        v-model="integration.isEnabled"
        class="q-ml-md"
      />

      <QSpace />

      <div
        v-if="loading"
        class="row q-gutter-xs"
      >
        <QSkeleton
          type="QBtn"
        />
        <QSkeleton
          type="QBtn"
        />
      </div>
      <div
        v-else
        class="q-gutter-xs"
      >
        <QBtn
          color="primary"
          :label="t('Apply')"
          icon="mdi-content-save"
          :loading="saving"
          @click="handleApply"
        />
        <QBtn
          color="success"
          :label="t('Save')"
          icon="mdi-content-save"
          :loading="saving"
          @click="handleSave"
        />
      </div>
    </CardTitle>

    <QSeparator />

    <PrimaryErrorBanner />
    <QSlideTransition>
      <div v-if="integration.isEnabled">
        <QCardSection>
          <div class="row q-col-gutter-md">
            <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-2">
              <QInput
                v-if="editingToken !== null"
                v-model="editingToken"
                :label="t('Access Token')"
                autofocus
                bottom-slots
              >
                <template #append>
                  <QBtn
                    flat
                    round
                    icon="mdi-check"
                    color="positive"
                    :disable="!editingToken"
                    @click="handleSaveToken"
                  />
                  <QBtn
                    flat
                    round
                    icon="mdi-cancel"
                    color="negative"
                    @click="editingToken = null"
                  />
                </template>
                <template #hint>
                  <i18n-t keypath="You can get an access token on {tokensPageLink}">
                    <template #tokensPageLink>
                      <a href="https://online.moysklad.ru/app/#token">{{ t('Tokens page') }}</a>
                    </template>
                  </i18n-t>
                </template>
              </QInput>
              <QInput
                v-else
                :model-value="accessTokenMasked"
                readonly
                :label="t('Access Token')"
                stack-label
                :placeholder="t('Not specified')"
                bottom-slots
              >
                <template #append>
                  <QBtn
                    flat
                    round
                    icon="mdi-pencil"
                    @click="editingToken = ''"
                  />
                </template>
              </QInput>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
              <QSelect
                v-model="integration.storeId"
                :options="stores"
                option-value="id"
                option-label="name"
                emit-value
                map-options
                :label="t('Store')"
                stack-label
                :placeholder="t('Not specified')"
                :loading="loadingMoyskladData"
              >
                <template
                  v-if="!integration.storeId"
                  #selected
                >
                  <span class="text-grey-7">{{ t('Not specified') }}</span>
                </template>
              </QSelect>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
              <QSelect
                v-model="integration.organizationId"
                :options="organizations"
                option-value="id"
                option-label="name"
                emit-value
                map-options
                :label="t('Organization')"
                stack-label
                :placeholder="t('Not specified')"
                :loading="loadingMoyskladData"
              >
                <template
                  v-if="!integration.organizationId"
                  #selected
                >
                  <span class="text-grey-7">{{ t('Not specified') }}</span>
                </template>
              </QSelect>
            </div>
          </div>
        </QCardSection>

        <QSeparator />

        <QCardSection>
          <MoySkladSync
            :integration="data?.warehouse.moyskladIntegration"
            @sync-completed="handleSyncCompleted"
          />
        </QCardSection>

        <QExpansionItem
          v-if="data?.warehouse.moyskladIntegration.firstSyncResult"
          v-model="syncResultOpen"
          :label="t('Initial Sync Result')"
          switch-toggle-side
        >
          <MoySkladIntegrationSyncResult :result="data?.warehouse.moyskladIntegration.firstSyncResult" />
        </QExpansionItem>
      </div>
    </QSlideTransition>
  </QCard>
</template>

<script setup lang="ts">

import moyskladLogo from '@/assets/moysklad.svg';
import CardTitle from '@/components/CardTitle.vue';
import useBackend from '@/composables/useBackend';
import useBreadcrumbs from '@/composables/useBreadcrumbs';
import useErrorHandling from '@/composables/useErrorHandling';
import useNavHelpers from '@/composables/useNavHelpers';
import type {
  MoySkladIntegration,
  MutationSaveMoySkladIntegrationArgs,
  Warehouse,
} from '@/graphql/types';
import ROUTES from '@/router/routeNames';
import type * as MoySkladAPI from '@/types/moySkladApi';
import MoySkladIntegrationSyncResult from '@/views/Integrations/MoySkladIntegrationSyncResult.vue';
import MoySkladSync from '@/views/Integrations/MoySkladSync.vue';
import { gql, useMutation, useQuery } from '@urql/vue';
import { useEventBus } from '@vueuse/core';
import { HTTPError } from 'ky';
import { QInput } from 'quasar';
import { prop } from 'ramda';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import useStore from '@/stores/root';

const { navigateBack } = useNavHelpers();

const { clearErrors, fillErrorsFromGraphQLError, primaryError, PrimaryErrorBanner } = useErrorHandling();

const { t } = useI18n();

useBreadcrumbs([t('Integrations'), t('MoySklad')]);

const { data, executeQuery, fetching: loading } = useQuery<{ warehouse: Warehouse }>({
  query: gql`
    query GetWarehouseForMoySkladIntegrationEdit {
      warehouse { id moyskladIntegration {
        isEnabled
        accessToken
        storeId
        organizationId
        firstSyncResult {
          skippedProductsWithEmptyCode { id name url }
        }
      } }
    }
  `,
});

watch(data, data => {
  if (data) {
    integration.value       = { ...data?.warehouse.moyskladIntegration };
  }
});

const integration = ref<MoySkladIntegration>({
  isEnabled: false,
  accessToken: null,
  storeId: null,
  organizationId: null,
});

watch(() => integration.value.accessToken, token => {
  if (token) {
    loadMoySkladData();
  }
});

const syncResultOpen = ref(false);

async function handleSyncCompleted() {
  await executeQuery();

  if (data.value?.warehouse.moyskladIntegration.firstSyncResult) {
    syncResultOpen.value = true;
  }
}

const accessTokenMasked = computed(() => {
  const token = integration.value.accessToken;

  if (!token) {
    return '';
  }

  return token.length > 8
    ? token.slice(0, 4) + '****' + token.slice(-4)
    : token;
});

const editingToken = ref<string | null>(null);

async function handleSaveToken() {
  integration.value.accessToken = editingToken.value!;
  editingToken.value = null;
}

const {
  executeMutation: doSave,
  fetching: saving,
} = useMutation<{ integration: MoySkladIntegration }, MutationSaveMoySkladIntegrationArgs>(
  gql`
    mutation SaveMoySkladIntegration(
      $isEnabled: Boolean!,
      $accessToken: String,
      $storeId: String,
      $organizationId: String,
    ) {
      integration: saveMoySkladIntegration(
        isEnabled: $isEnabled,
        accessToken: $accessToken,
        storeId: $storeId,
        organizationId: $organizationId,
      ) {
        isEnabled
        accessToken
        storeId
        organizationId
      }
    }
  `
);

const { emit: emitRefreshIntegrations } = useEventBus('refresh-integrations');

async function handleSave() {
  save();
  navigateBack({ name: ROUTES.DASHBOARD });
}

async function handleApply() {
  save();
}

async function save() {
  clearErrors();
  const { data, error } = await doSave(integration.value);
  if (error) {
    fillErrorsFromGraphQLError(error);
    return;
  }
  integration.value = { ...data!.integration };
  await useStore().updateUser();
  emitRefreshIntegrations();
}

const stores = ref<MoySkladAPI.Store[]>([]);

const organizations = ref<MoySkladAPI.Organization[]>([]);

const backend = useBackend();

const loadingMoyskladData = ref(false);

async function moySkladAPIRequest<TResponse extends object>(path: string): Promise<TResponse> {
  try {
    return await backend.moySkladAPIProxy(path, integration.value.accessToken!) as TResponse;
  } catch (e) {
    let error = t('Unknown Error');
    if (e instanceof HTTPError) {
      const errorResponse = await e.response.json();
      error = errorResponse.errors.map(prop('error')).join(', ');
    } else if (e instanceof Error) {
      error = e.message;
    }

    throw error;
  }
}

async function loadMoySkladData() {
  if (!integration.value.accessToken) {
    return;
  }
  loadingMoyskladData.value = true;
  try {
    await loadStores();
    await loadOrganizations();
    await loadUserSettings();
    clearErrors();
  } catch (e) {
    primaryError.value = e as string;
  } finally {
    loadingMoyskladData.value = false;
  }
}

async function loadUserSettings() {
  if (!integration.value.accessToken || integration.value.storeId && integration.value.organizationId) {
    return;
  }

  const data = await moySkladAPIRequest<MoySkladAPI.UserSettings>('context/usersettings');

  if (!integration.value.storeId) {
    const storeUrl = new URL(data.defaultPlace.meta.href);
    integration.value.storeId = storeUrl.pathname.slice(storeUrl.pathname.lastIndexOf('/') + 1);
  }
  if (!integration.value.organizationId) {
    const orgUrl = new URL(data.defaultCompany.meta.href);
    integration.value.organizationId = orgUrl.pathname.slice(orgUrl.pathname.lastIndexOf('/') + 1);
  }
}

async function loadStores() {
  const data = await moySkladAPIRequest<MoySkladAPI.ListResponse<MoySkladAPI.Store>>('entity/store');

  stores.value = data.rows;
}

async function loadOrganizations() {
  const data = await moySkladAPIRequest<MoySkladAPI.ListResponse<MoySkladAPI.Organization>>('entity/organization');

  organizations.value = data.rows;
}

</script>

<i18n lang="yaml">
ru:
  Integrations: Интеграции
  MoySklad: МойСклад
  Access Token: Токен доступа
  You can get an access token on {tokensPageLink}: Вы можете получить токен доступа на {tokensPageLink}
  Tokens page: странице Токены
  Unknown Error: Неизвестная ошибка
  Store: Склад
  Organization: Юрлицо
  Initial Sync Result: Результаты начальной синхронизации
en:
  Integrations: Integrations
  MoySklad: MoySklad
  Access Token: Access Token
  You can get an access token on {tokensPageLink}: You can get an access token on {tokensPageLink}
  Tokens page: Tokens page
  Unknown Error: Unknown Error
  Store: Store
  Organization: Organization
  Initial Sync Result: Initial Sync Result
</i18n>
