<template>
  <QDialog>
    <QCard>
      <CardTitle>{{ t('Warning') }}</CardTitle>
      <BaseAlert type="warning">
        {{ t('Container already contains other orders. Start selection into this container?') }}
      </BaseAlert>
      <QCardActions align="right">
        <QBtn
          :label="t('No')"
          @click="emit('cancel')"
        />
        <QBtn
          color="primary"
          :label="t('Start Selection')"
          @click="emit('confirm')"
        />
      </QCardActions>
    </QCard>
  </QDialog>
</template>

<script setup lang="ts">

import { useI18n } from 'vue-i18n';
import BaseAlert from '@/components/BaseAlert.vue';
import CardTitle from '@/components/CardTitle.vue';

const { t } = useI18n();

const emit = defineEmits<{
  (e: 'cancel'): void;
  (e: 'confirm'): void;
}>();

</script>

<i18n lang="yaml">
ru:
  Container already contains other orders. Start selection into this container?: >
    Контейнер уже содержит другие заказы. Начать отбор в этот контейнер?
  Start Selection: Начать отбор
en:
  Container already contains other orders. Start selection into this container?: >
    Container already contains other orders. Start selection into this container?
  Start Selection: Start Selection
</i18n>
