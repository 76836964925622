<template>
  <div class="column justify-center">
    <div
      class="column no-wrap items-center text-h5 q-px-xs"
      style="max-width: 100%"
    >
      <div
        class="text-no-wrap scroll-x"
        :class="{ 'text-grey-4': step === 'to' }"
        style="max-width: 100%"
      >
        {{ expectedStorageFromLabel }}
      </div>
      <template v-if="!sameExpectedStorages && expectedStorageTo">
        <QIcon name="mdi-arrow-down" />
        <div
          class="text-no-wrap scroll-x"
          :class="{ 'text-grey-4': step === 'from' }"
          style="max-width: 100%"
        >
          {{ expectedStorageToLabel }}
        </div>
      </template>
    </div>

    <BaseScanField
      :search-fn="searchStorageByBarcode"
      :rules="step === 'from' ? storageFromRules : storageToRules"
      :hint="step === 'from' ? t('Scan First Storage') : t('Scan Second Storage')"
      :placeholder="t('Storage')"
      :not-found-message="t('Storage not found')"
      :disabled="disable"
      class="q-px-lg"
      @scan="handleScanStorage"
    />
  </div>
</template>

<script setup lang="ts">
import BaseScanField from '@/components/Mobile/BaseScanField.vue';
import useSpeaker from '@/composables/useSpeaker';
import type { Cell, Container, Storage } from '@/graphql/types';
import type { FunctionValidationRule, StoragesScanStep } from '@/types';
import { computed, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    expectedStorages: [Storage, Storage | null] | null;
    searchStorage?: (barcode: string, step: StoragesScanStep) => Promise<Storage | null>;
    storageFromRules?: FunctionValidationRule<Storage>[];
    storageToRules?: FunctionValidationRule<Storage>[];
    validateStorageTo: (storageTo: Storage) => Promise<boolean>;
    disable?: boolean;
  }>(),
  {
    storageFromRules: () => [],
    storageToRules: () => [],
    validateStorageTo: async () => false,
  },
);

const emit = defineEmits<{
  scan: [storages: [Storage, Storage]];
  'scan:storageFrom': [storage: Storage];
  'scan:storageTo': [storage: Storage];
  'warning:storageTo': [storage: Storage];
}>();

const scannedStorageFrom = ref<Storage | null>(null);
const scannedStorageTo = ref<Storage | null>(null);

onMounted(() => {
  scannedStorageFrom.value = null;
  scannedStorageTo.value = null;
});

const expectedStorageFrom = computed(() => props.expectedStorages?.[0] ?? null);
const expectedStorageTo = computed(() => props.expectedStorages?.[1] ?? null);

const sameExpectedStorages = computed(
  () => props.expectedStorages && props.expectedStorages[0].id === props.expectedStorages[1]?.id,
);

const expectedStorageFromLabel = computed(
  () =>
    expectedStorageFrom.value?.name
    ?? scannedStorageFrom.value?.name
    ?? '[' + t('From Storage') + ']',
);

const expectedStorageToLabel = computed(
  () =>
    expectedStorageTo.value?.name ?? scannedStorageTo.value?.name ?? '[' + t('To Storage') + ']',
);

const step = computed<StoragesScanStep>(() => (scannedStorageFrom.value ? 'to' : 'from'));

function searchStorageByBarcode(barcode: string) {
  if (step.value === 'from' && expectedStorageFrom.value) {
    if (expectedStorageFrom.value.barcode !== barcode) {
      throw new Error(t('The storage should match specified'));
    }
    return expectedStorageFrom.value;
  }

  if (step.value === 'to' && expectedStorageTo.value) {
    if (expectedStorageTo.value.barcode !== barcode) {
      throw new Error(t('The storage should match specified'));
    }
    return expectedStorageTo.value;
  }

  return props.searchStorage?.(barcode, step.value) ?? null;
}

const speaker = useSpeaker();

watch(() => step.value, speakExpectedContainersName, { immediate: true });

function speakExpectedContainersName() {
  if (expectedStorageFrom.value && step.value === 'from') {
    speaker.speak(t('From {name}', { name: expectedStorageFrom.value.name }));
  }
  if (expectedStorageTo.value && step.value === 'to') {
    speaker.speak(t('To {name}', { name: expectedStorageTo.value.name }));
  }
}

async function handleScanStorage(storage: Container | Cell) {
  if (!expectedStorageFrom.value && !expectedStorageTo.value) {
    speaker.speak(
      step.value === 'from'
        ? t('From {name}', { name: storage.name })
        : t('To {name}', { name: storage.name }),
    );
  }

  if (step.value === 'from') {
    scannedStorageFrom.value = storage;
  } else if (await props.validateStorageTo(storage)) {
    scannedStorageTo.value = storage;
  }
  if (sameExpectedStorages.value) {
    scannedStorageTo.value = storage;
  }
}

watch(scannedStorageFrom, storage => {
  if (storage) {
    emit('scan:storageFrom', storage);
  }
});

watch(scannedStorageTo, storage => {
  if (storage) {
    emit('scan:storageTo', storage);
  }
});

watch([scannedStorageFrom, scannedStorageTo], ([from, to]) => {
  if (from && to) {
    emit('scan', [from, to]);
  }
});
</script>

<i18n lang="yaml" src="../plugins/i18n/sharedMessages/scanning.yaml"></i18n>
<i18n lang="yaml" src="../plugins/i18n/sharedMessages/speaking.yaml"></i18n>

<i18n lang="yaml">
ru:
  From Storage: Из ячейки
  To Storage: В ячейку

en:
  From Storage: From Storage
  To Storage: To Storage
</i18n>
