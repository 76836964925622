<template>
  <QBtn
    icon="mdi-content-copy"
    :title="t('Copy to Clipboard')"
    v-bind="$attrs"
    @click="copy(text)"
  />
</template>

<script setup lang="ts">

import useClipboard from '@/composables/useClipboard';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const { text } = defineProps<{
  text: string;
}>();

const { copy } = useClipboard();

</script>

<i18n lang="yaml">
ru:
  Copy to Clipboard: Скопировать в буфер обмена
en:
  Copy to Clipboard: Copy to Clipboard
</i18n>
