<template>
  <div class="col column no-wrap full-width justify-center">
    <div
      class="column no-wrap items-center text-h5 q-px-xs"
      style="max-width: 100%"
    >
      <div
        class="text-no-wrap scroll-x"
        style="max-width: 100%"
      >
        {{ expectedStorageToLabel }}
      </div>
    </div>

    <BaseScanField
      :search-fn="searchStorageByBarcode"
      :hint="t('Scan Second Storage')"
      :placeholder="t('Storage')"
      :not-found-message="t('Storage not found')"
      class="q-px-lg"
      @scan="handleScanStorage"
    />
  </div>
</template>

<script setup lang="ts">
import BaseScanField from '@/components/Mobile/BaseScanField.vue';
import type { Cell, Container, Storage } from '@/graphql/types';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps<{
  expectedStorage: Storage;
}>();

const emit = defineEmits<{
  scan: [storage: Storage];
}>();

const expectedStorageTo = computed(() => props.expectedStorage);

const expectedStorageToLabel = computed(
  () => expectedStorageTo.value?.name ?? '[' + t('To Storage') + ']',
);

function searchStorageByBarcode(barcode: string) {
  if (expectedStorageTo.value.barcode !== barcode) {
    throw new Error(t('The storage should match specified'));
  }
  return expectedStorageTo.value;
}

async function handleScanStorage(storage: Container | Cell) {
  emit('scan', storage);
}
</script>

<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/scanning.yaml"></i18n>
<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/speaking.yaml"></i18n>

<i18n lang="yaml">
ru:
  To Storage: В ячейку

en:
  To Storage: To Storage
</i18n>
