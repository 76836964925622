<template>
  <QDialog
    v-model="isOpen"
    :persistent="fetching"
  >
    <QCard style="width: 400px">
      <CardTitle>
        {{ t('Correct Amount') }}
        <template #subtitle>
          <div
            class="ellipsis"
            :title="movement.storable.productPack.product.name"
          >
            {{ movement.storable.productPack.product.sku }} {{ movement.storable.productPack.product.name }}
          </div>
        </template>
      </CardTitle>

      <QSeparator />

      <QCardSection>
        <QInput
          :model-value="amountBefore"
          :label="t('Current Amount')"
          :suffix="amountSuffix(amountBefore)"
          readonly
          class="text-right"
          bottom-slots
        >
          <template #hint>
            <div class="text-right">
              {{ t('Total Arrived {amount}', { amount: totalArrivedAmount }) }}
              {{ movement.storable.productPack.minMeasurementUnit.shortName }}
            </div>
          </template>
        </QInput>
        <QInput
          v-model.number="amountAfter"
          v-select-on-focus
          :label="t('New Amount')"
          :suffix="amountSuffix(amountAfter)"
          class="text-right"
          autofocus
          bottom-slots
        >
          <template #hint>
            <div class="row justify-between">
              <div>
                <template v-if="supplyAmount">
                  {{ t('Supply') }} {{ supplyAmount }}
                  {{ movement.storable.productPack.minMeasurementUnit.shortName }}
                </template>
              </div>
              <div>
                {{ t('Total Arrived {amount}', { amount: totalArrivedAmount + diff }) }}
                {{ movement.storable.productPack.minMeasurementUnit.shortName }}
              </div>
            </div>
          </template>
        </QInput>
      </QCardSection>

      <PrimaryErrorBanner animated />

      <QSeparator />

      <QCardActions>
        <QBtn
          :label="t('Close')"
          :disable="fetching"
          @click="isOpen = false"
        />

        <QSpace />

        <QBtn
          :label="t('Save')"
          icon="mdi-check"
          color="positive"
          :loading="fetching"
          @click="correctAmount"
        />
      </QCardActions>
    </QCard>
  </QDialog>
  <QBtn
    v-bind="$attrs"
    icon="mdi-pencil"
    :title="t('Correct Amount')"
    :loading="loading"
    @click="isOpen = true"
  />
</template>

<script setup lang="ts">
import CardTitle from '@/components/CardTitle.vue';
import useErrorHandling from '@/composables/useErrorHandling';
import vSelectOnFocus from '@/directives/selectOnFocus';
import type { Arrival, Movement, MutationCorrectArrivalAmountArgs, } from '@/graphql/types';
import { gql, useMutation } from '@urql/vue';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const { fillErrorsFromGraphQLError, clearErrors, PrimaryErrorBanner } = useErrorHandling();

const props = defineProps<{
  arrival: Arrival;
  movement: Movement;
  loading: boolean;
}>();

const emit = defineEmits<{
  save: [{ movement: Movement | null; oldId: string }];
}>();

const amountBefore = ref(0);
const amountAfter = ref(0);

const diff = computed(() => (amountAfter.value - amountBefore.value) * props.movement.storable.productPack.quantityInMinMeasurementUnits);

const isOpen = ref(false);

watch(isOpen, () => {
  amountAfter.value = amountBefore.value = props.movement.amount;
}, { immediate: true });

const {
  executeMutation,
  fetching,
} = useMutation<{ movement: Movement }, MutationCorrectArrivalAmountArgs>(
  gql`
    mutation CorrectArrivalAmount($arrivalId: ID!, $movementId: ID!, $storageUnitId: ID!, $amount: Float!) {
      movement: correctArrivalAmount(arrivalId: $arrivalId, movementId: $movementId, storageUnitId: $storageUnitId, amount: $amount) {
        id
          id
          amount
          storageFrom { id }
          storageTo { id }
          storable {
            id
            batch {
              id
              name
              product {
                id
                accountingModel { id batchDisplayFormat }
              }
            }
            productPack {
              id
              product { id sku name }
              barcodes { barcode }
              measurementUnit { id name shortName }
              quantityInMinMeasurementUnits
              minMeasurementUnit { id shortName }
              smallerProductPack { id }
            }
          }
      }
    }
  `,
);

async function correctAmount() {
  clearErrors();

  const { data, error } = await executeMutation({
    arrivalId: props.arrival.id,
    movementId: props.movement.id,
    storageUnitId: props.movement.storable.id,
    amount: amountAfter.value,
  });

  if (error) {
    fillErrorsFromGraphQLError(error);
    return;
  }

  emit('save', { movement: data!.movement, oldId: props.movement.id });

  isOpen.value = false;
}

function amountSuffix(amount: number) {
  let result = props.movement.storable.productPack.measurementUnit.shortName;
  if (props.movement.storable.productPack.smallerProductPack) {
    result += ` (${amount * props.movement.storable.productPack.quantityInMinMeasurementUnits} ${props.movement.storable.productPack.minMeasurementUnit.shortName})`;
  }
  return result;
}

const supplyAmount = computed(() =>
  props.arrival.supply?.items
    .filter(i => i.productPack.product.id === props.movement.storable.productPack.product.id)
    .reduce((sum, item) => sum + item.amount * item.productPack.quantityInMinMeasurementUnits, 0)
  ?? null);

const totalArrivedAmount = computed(() => props.arrival.movements
  .filter(m => m.storable.productPack.product.id === props.movement.storable.productPack.product.id)
  .reduce((sum, movement) => sum + movement.amount * movement.storable.productPack.quantityInMinMeasurementUnits, 0));

</script>

<i18n lang="yaml">
ru:
  Correct Amount: Корректировать количество
  Current Amount: Текущее количество
  New Amount: Новое количество
  Total Arrived {amount}: Всего принято {amount}

en:
  Correct Amount: Correct Amount
  Current Amount: Current Amount
  New Amount: New Amount
  Total Arrived {amount}: Total Arrived {amount}
</i18n>
