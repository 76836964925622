<template>
  <div class="col column no-wrap">
    <ScanStoragesPair
      :expected-storages="expectedStoragesToScan"
      :search-storage="searchStorage"
      :storage-to-rules="storageToRules"
      :validate-storage-to="validateStorageTo"
      :disable="store.completing"
      class="col"
      @scan:storage-from="handleScanStorageFrom"
      @scan:storage-to="handleScanStorageTo"
      @warning:storage-to="handleWarning"
    />

    <PrimaryErrorBanner animated />
    <NonEmptyContainerWarningDialog
      v-model="nonEmptyContainerWarning"
      @cancel="handleCancelScanning"
      @confirm="handeConfirmScanning"
    />
    <ButtonsRow v-slot="{ buttonProps }">
      <KeyboardToggleButton v-bind="buttonProps" />
      <QBtn
        v-bind="buttonProps"
        :disable="store.completing"
        icon="mdi-close-circle-outline"
        @click="emit('cancel')"
      >
        {{ t('Cancel') }}
      </QBtn>
      <QBtn
        v-if="store.transfer?.task"
        v-bind="buttonProps"
        icon="mdi-format-list-checks"
        @click="emit('show-task')"
      >
        {{ t('Task') }}
      </QBtn>
      <TransferHistoryButton
        v-bind="buttonProps"
        @click="emit('show-history')"
      />
      <TransferCompleteButton
        v-if="store.movements.length > 0"
        v-bind="buttonProps"
        :loading="store.completing"
        @complete="complete"
      />
    </ButtonsRow>
  </div>
</template>

<script setup lang="ts">
import ButtonsRow from '@/components/Mobile/ButtonsRow.vue';
import ScanStoragesPair from '@/components/ScanStoragesPair.vue';
import useErrorHandling from '@/composables/useErrorHandling';
import useNavHelpers from '@/composables/useNavHelpers';
import useOmniInput from '@/composables/useOmniInput';
import useSpeaker from '@/composables/useSpeaker';
import StockForTransfer from '@/graphql/fragments/StockForTransfer';
import StorageForTransfer from '@/graphql/fragments/StorageForTransfer';
import StorageUnitForTransfer from '@/graphql/fragments/StorageUnitForTransfer';
import type { ContainerShipmentItem } from '@/graphql/types';
import {
  type Cell,
  type Container,
  type Stock,
  type Storage,
  TransferTaskKindEnum,
} from '@/graphql/types';
import ROUTES from '@/router/routeNames';
import useTransferProcessStore from '@/stores/transferProcess';
import type { StoragesScanStep } from '@/types';
import TransferCompleteButton from '@/views/Mobile/Transfer/TransferCompleteButton.vue';
import TransferHistoryButton from '@/views/Mobile/Transfer/TransferHistoryButton.vue';
import { gql, useClientHandle } from '@urql/vue';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import NonEmptyContainerWarningDialog from '@/views/Mobile/NonEmptyContainerWarningDialog.vue';
import { useConfirmDialog } from '@vueuse/core';

const { navigateBack } = useNavHelpers();

const speaker = useSpeaker();

const { fillErrorsFromGraphQLError, clearErrors, PrimaryErrorBanner } = useErrorHandling();

const { t } = useI18n();

const store = useTransferProcessStore();

const emit = defineEmits<{
  cancel: [];
  'show-task': [];
  'show-history': [];
}>();

const expectedStoragesToScan = computed(() => {
  if (store.isClusterSelection) {
    return store.storagesPairsToScan[0] ? [store.storagesPairsToScan[0][0], null] : null;
  }

  return store.storagesPairsToScan[0] ?? null;
});

const storageFromScanQuery = gql`
  ${StorageForTransfer}
  query GetStorageByBarcodeForTransfer($barcode: String!) {
    storage: storageByBarcode(barcode: $barcode) {
      ...StorageForTransfer
    }
  }
`;

const storageToScanQuery = gql`
  ${StorageForTransfer}
  ${StorageUnitForTransfer}
  ${StockForTransfer}
  query GetSecondStorageByBarcodeForTransfer($transferId: ID!, $barcode: String!) {
    storage: storageByBarcode(barcode: $barcode) {
      ...StorageForTransfer
    }
    stocks: stocksByStorageBarcode(transferId: $transferId, barcode: $barcode) {
      ...StockForTransfer
    }
  }
`;

const { client: urql } = useClientHandle();

async function searchStorage(barcode: string, step: StoragesScanStep) {
  const { data, error } = await urql.query<{ storage: Storage | null; stocks?: Stock[] }>(
    step === 'from' ? storageFromScanQuery : storageToScanQuery,
    {
      barcode,
      transferId: store.transfer!.id,
    },
  );

  if (error) {
    throw error;
  }

  if (data!.stocks) {
    store.stocksInStorageTo = data!.stocks;
  }

  return data!.storage;
}

function handleScanStorageFrom(storage: Storage) {
  store.storageFrom = storage as Container | Cell;
  store.updateTransferState();
  store.availableStorages.push(storage);
}

const storageWithWarning = ref<Container | null>(null);

function handleScanStorageTo(storage: Storage) {
  if (store.isClusterSelection) {
    store.resetSlides();
  } else {
    store.storageTo = storage;
    store.updateTransferState();
    store.availableStorages.push(storage);
  }
}

const {
  reveal: handleWarning,
  isRevealed: nonEmptyContainerWarning,
  confirm: handeConfirmScanning,
  cancel: handleCancelScanning,
} = useConfirmDialog();

function storageToHasWarnings(storageTo: Storage): boolean {
  const containsOtherOrders = (i: ContainerShipmentItem) =>
    store.transfer!.items.some(o => o.shipment!.id !== i.shipment.id);

  return (
    store.transfer?.task?.kind === TransferTaskKindEnum.SELECTION
    && storageTo.__typename === 'Container'
    && (storageTo as Container).itemsForShipping.some(containsOtherOrders)
  );
}

async function validateStorageTo(storageTo: Storage): Promise<boolean> {
  if (storageToHasWarnings(storageTo)) {
    storageWithWarning.value = storageTo as Container;
    const { isCanceled } = await handleWarning();
    return !isCanceled;
  } else {
    return true;
  }
}

async function complete() {
  clearErrors();

  const { error } = await store.completeTransfer();

  if (error) {
    fillErrorsFromGraphQLError(error);
    return;
  }

  speaker.speak(t('Completed'));

  await navigateBack({ name: ROUTES.TRANSFER_DASHBOARD });

  store.clearStateForTransfer();
  store.transfer = null;
}

const storageToRules = [
  store.validation.storageToCellTypeRule,
  store.validation.storageToContainerTypeRule,
  store.validation.storageFromCellTypeRule,
  store.validation.storageFromContainerTypeRule,
];

const { KeyboardToggleButton } = useOmniInput({ skip: true });
</script>

<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/speaking.yaml"></i18n>
<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/scanning.yaml"></i18n>
